import React from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';
import { useSelector, useDispatch } from "react-redux";
import { hideSnackBar } from '../redux/action';

export default function SnackBar() {

  const messageData = useSelector((state) => state.handleSnackBar);
  const dispatch = useDispatch();
  const handleClose = (event, reason) => {
    const messageDetail = {
      state: false,
      message: messageData.message,
      messageType: messageData.messageType
    }
    dispatch(hideSnackBar(messageDetail));
  };

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar open={messageData.state} autoHideDuration={4000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <Alert onClose={handleClose} severity={messageData.messageType} sx={{ width: '100%' }} variant='filled'>
          {messageData.message}
        </Alert>
      </Snackbar>
    </Stack>
  );
}