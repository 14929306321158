import { Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const EmptyCart = () => {
    return (
        <div className="container">
        <div className="row">
          <div className="col-md-12 py-5 bg-light text-center">
            <h4 className="p-3 display-5 black-font">Your Cart is Empty</h4>
            <Link to="/" className="">
              <Button variant="contained" sx={{ textTransform : 'capitalize',  bgcolor: '#000000', borderRadius : '8px', ":hover": { bgcolor: "#EA6724" } }}>
              <i className="fa fa-arrow-left mr-2"></i> Continue Shopping
              </Button>
            </Link>
          </div>
        </div>
      </div>
    );
};

export default EmptyCart;