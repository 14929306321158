import React from 'react'
import { Grid, TextField, Typography, MenuItem, InputLabel, Select } from "@mui/material";
import FormControl from '@mui/material/FormControl';
import HblPay from '../assets/hbl-pay.webp';

const PaymentInformation = (props) => {
    const {cardDetails, setCardDetails } = props;

    var monthArray = [
        { label: 'January', value: '01' },
        { label: 'Febuary', value: '02' },
        { label: 'March', value: '03' },
        { label: 'April', value: '04' },
        { label: 'May', value: '05' },
        { label: 'June', value: '06' },
        { label: 'July', value: '07' },
        { label: 'August', value: '08' },
        { label: 'September', value: '09' },
        { label: 'October', value: '10' },
        { label: 'November', value: '11' },
        { label: 'December', value: '12' }
    ];
    var yearArray = [];

    if (yearArray.length < 1) {
        for (let i = 0; i < 22; i++) {
            yearArray.push(new Date().getFullYear() + i)
        }
    }

    return (
        <React.Fragment>
            <Typography component="div" sx={{ backgroundColor: "#009591", p: 1 }}>
                <img src={HblPay} width="auto" height="50" alt='HPL - Payment' />
            </Typography>
            <Grid container mt={2}  >
                {/* <Grid item xs={12} >
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label" sx={{alignItems : 'center'}}
                            name="card_type">
                                <Grid item xs={12} sm={2}>
                                     <FormLabel id="demo-row-radio-buttons-group-label">Card Type</FormLabel>
                                </Grid>
                                <Grid item xs={12} sm={5} md={4} lg={3} direction='column'>
                            <FormControlLabel sx={{ ml: 1, mr: 3 }} value="001" control={<Radio />} label="Visa" />
                            <img src="./assets/visa2.png" width="40" height="30" alt='VISA' />
                                </Grid>
                                <Grid item xs={12} sm={5} md={5} lg={4}>

                            <FormControlLabel sx={{ ml: 1 }} value="003" control={<Radio />} label="Mastercard" />
                            <img src="./assets/master.jpeg" width="40" height="30" alt='MasterCard' />
                                </Grid>
                        </RadioGroup>
                </Grid> */}
                <Grid item xs={12} md={12} lg={12} >
                    <TextField fullWidth={true} label="Card Number" name="card_number" color='success'
                    inputProps={{maxLength : 20}}
                     value={cardDetails.card_number} required variant="outlined"  onChange={(event) => { setCardDetails(preValue => {return {...preValue, [event.target.name] : event.target.value}}) }} />
                </Grid>
                <Grid container columnSpacing={1}>

                    <Grid item xs={12} sm={12} md={6} lg={3}  >
                        <FormControl sx={{ mt: 2, maxWidth: '100%', minWidth : '100%'  }} required>
                            <InputLabel id="demo-simple-select-filled-label" color='success' >Expiration Month</InputLabel>
                            <Select value={cardDetails.month} name="month" color='success' onChange={(event) => { setCardDetails(preValue => {return {...preValue, [event.target.name] : event.target.value}}) }}
                                labelId="demo-simple-select-filled-label" label="Expiration Month"
                                id="demo-simple-select-filled">
                                {
                                    monthArray.map((month, index) => {
                                        return (
                                            <MenuItem key={index} value={month.value} >{month.label}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3} >
                        <FormControl variant="outlined" sx={{ mt: 2, maxWidth: '100%', minWidth : '100%'  }} required>
                            <InputLabel id="expiration-year-label" color='success' >Expiration Year</InputLabel>
                            <Select value={cardDetails.year} name="year" color='success'
                                labelId="expiration-year-label" label="Expiration Year" onChange={(event) => { setCardDetails(preValue => {return {...preValue, [event.target.name] : event.target.value}}) }}
                                id="expiration-year">

                                {

                                    yearArray.map((year, index) => {
                                        return (
                                            <MenuItem key={index} value={year} >{year}</MenuItem>
                                        )
                                    })
                                }

                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3} >
                        <FormControl variant="outlined" sx={{ mt: 2, maxWidth: '100%', minWidth : '100%'  }} required>
                            <InputLabel id="expiration-year-label" color='success' >Account Type</InputLabel>
                            <Select value={cardDetails.card_account_type} name="card_account_type" color='success'
                                labelId="expiration-year-label" label="Expiration Year" onChange={(event) => { setCardDetails(preValue => {return {...preValue, [event.target.name] : event.target.value}}) }}
                                id="expiration-year">
                                <MenuItem value="CR">Credit card</MenuItem>
                                <MenuItem value="DB">Debit card</MenuItem>
                                <MenuItem value="CH">Checking account</MenuItem>
                                {/* <MenuItem value="CR">Credit card account</MenuItem> */}
                                <MenuItem value="SA">Savings account</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3} >
                        <FormControl variant="outlined" sx={{ mt: 2, maxWidth: '100%', minWidth : '100%'  }} required>
                            <InputLabel id="expiration-year-label" color='success' >Card Type</InputLabel>
                            <Select value={cardDetails.card_type} name="card_type" color='success'
                                labelId="expiration-year-label" label="Expiration Year" onChange={(event) => { setCardDetails(preValue => {return {...preValue, [event.target.name] : event.target.value}}) }}
                                id="expiration-year">
                                <MenuItem value="001">Visa</MenuItem>
                                <MenuItem value="002">Mastercard</MenuItem>
                                {/* <MenuItem value="CH">American Express</MenuItem>
                                <MenuItem value="CR">Discover</MenuItem>
                                <MenuItem value="SA">Diners Club—cards starting with 54 or 55 are rejected.</MenuItem>
                                <MenuItem value="">Carte Blanche</MenuItem>
                                <MenuItem value="">JCB</MenuItem>
                                <MenuItem value="">EnRoute</MenuItem>
                                <MenuItem value="">JAL</MenuItem>
                                <MenuItem value="">Maestro UK Domestic</MenuItem>
                                <MenuItem value="">Delta</MenuItem>

                                <MenuItem value="">Visa Electron</MenuItem>
                                <MenuItem value="">Dankort</MenuItem>
                                <MenuItem value="">Cartes Bancaires</MenuItem>
                                <MenuItem value="">Carta Si</MenuItem>
                                <MenuItem value="">Maestro International</MenuItem>
                                <MenuItem value="">GE Money UK card</MenuItem>
                                <MenuItem value="">Hipercard (sale only)</MenuItem>
                                <MenuItem value="">Elo</MenuItem>
                                <MenuItem value="">Private Label</MenuItem> */}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3} >
                        <FormControl variant="outlined" sx={{ mt: 2, maxWidth: '100%', minWidth : '100%' }} required>
                            <InputLabel id="selection-tyoe-label" color='success' >Card Selection Type</InputLabel>
                            <Select value={cardDetails.card_type_selection_indicator} name="card_type_selection_indicator" color='success'
                                labelId="selection-tyoe-label" label="Card Selection Type" onChange={(event) => { setCardDetails(preValue => {return {...preValue, [event.target.name] : event.target.value}}) }}
                                id="selection-tyoe">
                                <MenuItem value="1">Cardholder</MenuItem>
                                <MenuItem value="0">Default Acquirer settings</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3}>
                        <TextField fullWidth={true} label="CVN" color='success'
                        inputProps={{maxLength : 4}}
                        name="card_cvn" value={cardDetails.card_cvn} sx={{ mt: 2 }} onChange={(event) => { setCardDetails(preValue => {return {...preValue, [event.target.name] : event.target.value}}) }}
                         required variant="outlined" />
                    </Grid>
                        <input type="text" hidden readOnly id="payment_method" name="payment_method" value="card" />
                        <input type="text" hidden readOnly id="payer_authentication_transaction_mode" name="payer_authentication_transaction_mode" value="S" />
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default PaymentInformation