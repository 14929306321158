import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Table, TableBody, TableCell, TableHead, TableRow, TableContainer, Paper, tableCellClasses, Typography } from '@mui/material';
import {styled } from 'styled-components'

const StyledTableRow = styled(TableRow)({
  '&:nth-of-type(odd)': {
    backgroundColor: "white",
  },
  '&:nth-of-type(even)': {
    backgroundColor: "#ecf6ff",
  },
});

const StyledTableCell = styled(TableCell)({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#283b60',
    color: 'whitesmoke',
    fontSize : '16px',
    fontWeight : 600
  }
});

const StyledSecondaryTableCell = styled(TableCell)({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#46869e',
    color: 'whitesmoke',
    fontSize : '15px',
    fontWeight : 600
  }
});

export default function OrderDetailDialog(props) {

  const { open, setOpen, orderProducts } = props;

  const handleClose = () => {
    setOpen(false);
  }
  
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth='lg' fullWidth={true} sx={{overflow : 'scroll'}}
      >
        <DialogTitle id="alert-dialog-title" sx={{fontSize : '22px', fontWeight : 600}}>
          Order Details
        </DialogTitle>
        <hr/>
        <DialogContent>
          <Table sx={{width : 'fit-content', flexWrap : 'wrap', wordBreak : 'break-word', wordWrap : 'break-word'}} >
            <TableBody>
              <TableRow>
                <TableCell sx={{ borderBottom: 'none', padding: '5px' }}>
                  <Typography>Order Date :</Typography>
                </TableCell>
                <TableCell sx={{ borderBottom: 'none', padding: '5px' }}>
                  <Typography> {orderProducts && orderProducts.order && orderProducts.order.Date ? orderProducts.order.Date : "000"}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ borderBottom: 'none', padding: '5px' }}>
                  <Typography>Order Id :</Typography>
                </TableCell>
                <TableCell sx={{ borderBottom: 'none', padding: '5px' }}>
                 <Typography> {orderProducts && orderProducts.order && orderProducts.order.Id ? orderProducts.order.Id : "000"}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ borderBottom: 'none', padding: '5px' }}>
                  <Typography>Order By :</Typography>
                </TableCell>
                <TableCell sx={{ borderBottom: 'none', padding: '5px' }}>
                  <Typography>{orderProducts && orderProducts.order && orderProducts.order.Name ? orderProducts.order.Name : "Name Not Found"}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ borderBottom: 'none', padding: '5px' }}>
                  <Typography>Email :</Typography>
                </TableCell>
                <TableCell sx={{ borderBottom: 'none', padding: '5px' }}>
                  <Typography>{orderProducts && orderProducts.order && orderProducts.order.Email ? orderProducts.order.Email : "Email Not Found"}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ borderBottom: 'none', padding: '5px' }}>
                  <Typography>Phone : </Typography>
                </TableCell>
                <TableCell sx={{ borderBottom: 'none', padding: '5px' }}>
                  <Typography>{orderProducts && orderProducts.order && orderProducts.order.Phone ? orderProducts.order.Phone : "123-45678910"}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ borderBottom: 'none', padding: '5px' }}>
                  <Typography>Transaction Id : </Typography>
                </TableCell>
                <TableCell sx={{ borderBottom: 'none', padding: '5px' }}>
                  <Typography>{orderProducts && orderProducts.order && orderProducts.order.TransactionId ? orderProducts.order.TransactionId : "123-45678910"}</Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <TableContainer component={Paper} sx={{mt : 2}} >

            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell colSpan={4} align='center'>Product Details</StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledSecondaryTableCell align='center' >S.no</StyledSecondaryTableCell>
                  <StyledSecondaryTableCell align='center' sx={{ minWidth: '110px' }} >Name</StyledSecondaryTableCell>
                  <StyledSecondaryTableCell align='center' >Details</StyledSecondaryTableCell>
                  <StyledSecondaryTableCell align='center' >Price</StyledSecondaryTableCell>
                </TableRow>
                {
                  (orderProducts && orderProducts.product) ?
                    orderProducts.product.map((item, index) => {
                      return (

                        <StyledTableRow key={index} hover={true}>
                          <TableCell sx={{ borderBottom: 'none', padding: '5px', }} align='center' >{index + 1}</TableCell>
                          <TableCell sx={{ borderBottom: 'none', padding: '5px', }} align='center'  >
                            {item.Product.title}
                          </TableCell>
                          <TableCell sx={{ borderBottom: 'none', padding: '5px', }} align='center'  >
                            {item.Name}
                          </TableCell>
                          <TableCell sx={{ borderBottom: 'none', padding: '5px', }} align='center' >$ {item.Amount}</TableCell>
                        </StyledTableRow>

                      )
                    })
                    : ""
                }
              </TableHead>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Dialog>
    </div>
  );
}