import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Button, Typography } from '@mui/material';
import OrderDetailDialog from './OrderDetailDialog';
import { styled } from 'styled-components'

const StyledTableRow = styled(TableRow)({
  '&:nth-of-type(odd)': {
    backgroundColor: "white",
  },
  '&:nth-of-type(even)': {
    backgroundColor: "#ecf6ff",
  },
});

const StyledTableCell = styled(TableCell)({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#283b60',
    color: 'whitesmoke',
    fontSize: '16px',
    fontWeight: 600
  }
});

function NewlineText(props) {
  if(props && props.text && isNaN(props.text)){
    if(typeof props.text === "string" && props.text.includes("\n")){
      const text = props.text;
      const newText = text.split('\n').map(str => <p style={{marginBottom : '0px', whiteSpace : 'nowrap'}}>{str}</p>); 
      return newText;
    }
    else{
      return props.text;
    }
  }
  else{
    if(props && props.text){
      return props.text
    }
    else{
      return null
    }
  }
}

export default function StickyHeadTable(props) {
  const { rowData, columnData, ActionArray, handleOnClick } = props;
  const [rows, setRows] = useState([]);
  const [columns] = useState(columnData);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [orderDetail, setOrderDetail] = useState();

  useEffect(() => {
    if (rowData) {
      setRows(rowData);
    }
  }, [rowData]);

  const handleClickOpen = (order) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOrderDetail(null)
    setOpen(false);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ width: { md: '100%' }, mt: 3 }}>
      <TableContainer >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <StyledTableCell
                  key={index}
                  align="center"
                  style={{ minWidth: '80px', textTransform : 'capitalize' }}
                >
                  {column}
                </StyledTableCell>
              ))}
              {(ActionArray) ?
                <StyledTableCell>Action</StyledTableCell> : ""}
            </TableRow>
          </TableHead>
          <TableBody>
            {(rows.length > 0) ?
              rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <StyledTableRow hover role="checkbox" tabIndex={-1} key={index}>
                      {columns.map((column, indexNo) => {
                        var value = row[column];
                        return (
                          <TableCell key={indexNo} align="center" sx={{ padding: '8px' }}>
                             <NewlineText text={value} />
                          </TableCell>
                        );
                      })}
                      {(ActionArray) ?
                        <TableCell sx={{ padding: '8px' }} align="center" >
                          <Typography component="div" display="flex"  >
                            {
                              ActionArray.map((action, index) => {
                                return (
                                  <Button key={index} color='error' onClick={() => { handleOnClick(action.FUNCTION, row) }} sx={{ minWidth: 'fit-content' }}>{action.ICON}</Button>
                                )
                              })
                            }
                          </Typography>
                        </TableCell>
                        : null
                      }
                    </StyledTableRow>
                  );
                })
              :
              <StyledTableRow>
                <TableCell colSpan={columns.length + 1} align='center' ><Typography component="div">No Rows Found</Typography></TableCell>
              </StyledTableRow>
            }
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 50, 100]}
        component="div"
        count={rows.length > 0 ? rows.length : 1}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <OrderDetailDialog handleClickOpen={handleClickOpen} handleClose={handleClose} open={open} orderDetail={orderDetail} />
    </Paper>
  );
}