import { useState, useEffect } from "react";
import { Box, Typography, Button, TextField, Grid, Select, FormControl, MenuItem } from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import OrderDetailDialog from "./OrderDetailDialog";
import StickyHeadTable from "./Table";
import { Base_Url } from "../context";
import axios from "axios";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import OutboxOutlinedIcon from '@mui/icons-material/OutboxOutlined';
import { countryJSON } from "./countryJson";
import { ExportToExcel } from "./ExportExcel";
import { useDispatch } from "react-redux";
import { hideBackDrop, showBackDrop, showSnackBar } from "../redux/action";
import BackDrop from "./Backdrop";
import SnackBar from "./SnackBar";
// import EnhancedTable from "./SampleTable";


function AdminOrder(props) {
  const dispatch = useDispatch();
  const { children, value, index, ...other } = props;
  const [tableData, setTableData] = useState();
  const [columns] = useState(['Date', 'Name', 'Email', 'Product', 'IndividualParts', 'Type', 'Status', 'Agency Amount', 'Total Amount', 'Country', 'State']);
  const [ExcelColumns] = useState(['Date', 'Email', 'Name', 'User Notified', 'Product', 'Country', 'IndividualParts', 'Receipt No', 'Amount To be paid to Shandoor', 'Total Amount', 'Amount Rs HBL', 'Order #', 'PaymentToShandoor', 'Profit', 'Cybersource Status']);
  const [openOrderDetailDialog, setOpenOrderDialog] = useState(false);
  const [orderProducts, setOrderProduct] = useState([]);
  const [filterForm, SetFilterForm] = useState({
    fromDate: "",
    toDate: "",
    Status: "",
    CountryCode: "",
    Id: "",

  });
  const [tempTableData, setTemTableData] = useState();

  useEffect(() => {
    getOrderList();
  }, []);

  const getOrderList = async () => {
    const res = await axios.post(`${Base_Url}order/list`);
    setTableData(res.data);
  };

  const ShowDetails = async (rowItem) => {
    const res = await axios.post(`${Base_Url}order/productList/${rowItem.Id}`);
    setOrderProduct({ product: res.data, order: rowItem })
    setOpenOrderDialog(true)
  }

  const AddToShandoor = async (rowItem) => {
    try {
      if(rowItem.Status === "Processing"){
        dispatch(showBackDrop());
        const response = await axios.post(`${Base_Url}shandoor/create`, rowItem)
        const messageDetail = {
          state: true,
          message: response.status === 200 ? "Order Successfully Posted To Shandoor Agency." : response.data.message,
          messageType: response.status === 200 ? "success" : "error"
        }
        if (response.status === 200) {  
          const updatedData = tableData.map(item => {
            if (item.Id === rowItem.Id) {
              return { ...item, Status: 'Complete' };
            }
            return item;
          });
          setTableData(updatedData);
        }
        dispatch(showSnackBar(messageDetail));
        setTimeout(() => {
          dispatch(hideBackDrop());
        }, [1000])
      }
      if(rowItem.Status === "Pending" || rowItem.Status === "Cancelled" || rowItem.Status === "Failed"){
        dispatch(showSnackBar({state : true, message : "Order Amout has not been Paid.", messageType : "warning"}));
      }
      if(rowItem.Status === "Complete"){
        dispatch(showSnackBar({state : true, message : "Order Already Completed.", messageType : "success"}));
      }
    }
    catch (error) {
      const messageDetail = {
        state: true,
        message: error.message ? error.message : "Network Error",
        messageType: "error"
      }
      dispatch(showSnackBar(messageDetail));
      dispatch(hideBackDrop());
    }


  }

  const FilterOrder = async (event) => {
    event.preventDefault();
    var payloadObj = {};
    for (let i = 0; i < event.target.length; i++) {
      if (event.target[i].name !== "" && event.target[i].name !== null && event.target[i].name !== undefined && event.target[i].value !== "") {
        payloadObj[event.target[i].name] = event.target[i].value;
      }
    }

    if (payloadObj.fromDate && payloadObj.toDate) {

      var splitFromDate = payloadObj.fromDate.split("/");
      var splitToDate = payloadObj.toDate.split("/");
      var fromDate = new Date(payloadObj.fromDate);
      fromDate.setUTCHours(0, 0, 0, 0);
      fromDate.setUTCDate(Number(splitFromDate[1]));
      fromDate.setUTCFullYear(Number(splitFromDate[2]));
      fromDate.setUTCMonth(Number(splitFromDate[0] - 1));

      var toDate = new Date(payloadObj.toDate);
      toDate.setUTCHours(23, 59, 59, 999);
      toDate.setUTCDate(Number(splitToDate[1]));
      toDate.setUTCFullYear(Number(splitToDate[2]));
      toDate.setUTCMonth(Number(splitToDate[0] - 1));

      payloadObj.fromDate = fromDate;
      payloadObj.toDate = toDate;
    }

    const responseData = await axios.post(`${Base_Url}order/filter`, payloadObj);
    if (tempTableData === undefined) {
      setTemTableData(tableData);
    }
    setTableData(responseData.data);
  }

  const ChangeFilterForm = (event) => {
    const { name, value } = event.target;
    SetFilterForm(preValues => {
      return {
        ...preValues,
        [name]: value
      }
    })
  }

  const ClearFilter = () => {
    setTableData(tempTableData);
    SetFilterForm(
      {
        fromDate: "",
        toDate: "",
        Status: "",
        CountryCode: "",
        Id: "",

      }
    )
  }

  const handleOnClick = (action, data) => {
    const value = Object.keys(action);
    action[value[0]](data)
  }

  const ExportData = () => {
    const modifiedData = tableData.map(row => {
      delete row.Type;
      delete row.State;
      delete row.Id;
      delete row.Phone;
      return row;
    }
    )
    const reorderedData = modifiedData.map(item => {
      const reorderedItem = {};
      ExcelColumns.forEach(key => {
        reorderedItem[key] = item[key] ? item[key] : null;
      });
      return reorderedItem;
    });
    console.log(reorderedData)
    ExportToExcel(reorderedData, "order", "order.xlsx")
  }


  return (
    <>
      <BackDrop />
      <SnackBar />
      <Box mb={2}
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}>
        <Box component={'div'}>
          <Typography variant="h1" gutterBottom sx={{ fontWeight: 'bolder', fontSize: { xs: '28px', sm: '35px' } }} className="text-start mt-5" >Order Management</Typography>
        </Box>
        <Box>
          <Typography component="form" onSubmit={FilterOrder}>

            <Grid container columnGap={2} rowGap={2} mt={5}>
              <Grid item xs={12} sm={5} md={4} lg={2}>
                <label>From Date</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker slotProps={{ textField: { name: 'fromDate' } }} sx={{ width: "100%" }} />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={5} md={4} lg={2}>
                <label>To Date</label>
                <LocalizationProvider dateAdapter={AdapterDayjs} >
                  <DatePicker slotProps={{ textField: { name: 'toDate' } }} sx={{ width: "100%" }} />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={5} md={4} lg={2}>
                <label>Order Status</label>
                <FormControl fullWidth={true} size="medium">
                  <Select
                    labelId="status-select-helper-label"
                    id="status-select-helper"
                    value={filterForm.Status}
                    name="Status"
                    onChange={ChangeFilterForm}
                  >
                    <MenuItem value="" disabled>Select Status</MenuItem>
                    <MenuItem value="Pending">Pending</MenuItem>
                    <MenuItem value="Completed">Complete</MenuItem>
                    <MenuItem value="Dispatched">Dispatch</MenuItem>
                    <MenuItem value="Processing">Processing</MenuItem>
                    <MenuItem value="Failed">Failed</MenuItem>
                    <MenuItem value="Cancelled">Cancelled</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={5} md={4} lg={2}>
                <label>Country</label>
                <FormControl fullWidth={true} size="medium">
                  <Select labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    name="CountryCode"
                    value={filterForm.CountryCode}
                    onChange={ChangeFilterForm} >
                    <MenuItem disabled value="">Select Country</MenuItem>

                    {

                      Object.keys(countryJSON).map((fieldName, index) => {
                        return (
                          <MenuItem key={index} value={fieldName} >{countryJSON[fieldName]}</MenuItem>
                        )
                      })
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={5} md={4} lg={2}>
                <label>Order Id</label>
                <FormControl fullWidth={true} >
                  <TextField placeholder="" name="Id" onChange={ChangeFilterForm} value={filterForm.Id} />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container rowGap={2} columnGap={2} mt={2}>
              <Grid item xs={12} sm={5} md={4} lg={2} alignItems='flex-end' display='flex'>
                <Button variant="contained" type="submit" fullWidth={true} size="large" color="primary">Filter Order</Button>
              </Grid>
              <Grid item xs={12} sm={5} md={4} lg={2} alignItems='flex-end' display='flex'>
                <Button variant="contained" type="button" onClick={ClearFilter} fullWidth={true} size="large" color="secondary">Clear Filter</Button>
              </Grid>
              <Grid item xs={12} sm={5} md={4} lg={2} alignItems='flex-end' display='flex'>
                <Button variant="contained" type="button" onClick={() => { ExportData() }} fullWidth={true} size="large" color="success">Export To Excel</Button>
              </Grid>
            </Grid>
          </Typography>
        </Box>
        <StickyHeadTable rowData={tableData} columnData={columns} handleOnClick={handleOnClick}
          ActionArray={[{ ICON: <InfoOutlinedIcon titleAccess="Order Details" />, FUNCTION: { ShowDetails } }, { ICON: <OutboxOutlinedIcon titleAccess="ForwardToAgent" />, FUNCTION: { AddToShandoor } }]} />
        <OrderDetailDialog open={openOrderDetailDialog} setOpen={setOpenOrderDialog} orderProducts={orderProducts} />
      </Box>
      {/* <EnhancedTable /> */}
    </>
  )
}

export default AdminOrder