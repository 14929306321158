import React, {memo} from 'react'
import { Grid } from '@mui/material'

const TermCondition = memo(() => {
    console.log("Term condition")
    return (
        <>
            <Grid container justifyContent={'center'}>
                <Grid item xs={10}>
                    <h4 className='text-center'>Terms & Conditions</h4>
                    <ul className="mt-4">
                        <li className="mb-2 ml-1 mr-1">Pakdata has an arrangement with welfare trust(s) / parties who will undertake the responsibilities for the Qurbani program.</li>
                        <li className="mt-2 mb-2 ml-1 mr-1">I (user) hereby appoint Pakdata as my agent to perform / arrange Qurbani (Udhiya) and all related matters ( e.g. transportation of animal, slaughtering).</li>
                        <li className="mt-2 mb-2 ml-1 mr-1">The price includes agency cost ($38 per cow share, $80 per goat & $78 per sheep). It covers operational, bank charges, payment gateway charges and online transaction costs etc.</li>
                        <li className="mt-2 mb-2 ml-1 mr-1">If there is any amount left after sacrificing the animal, I (user) allow Pakdata to use that amount for any purpose it deems proper.</li>
                        <li className="mt-2 mb-2 ml-1 mr-1">Meat will be distributed amongst the needy while animal hides will be utilised to support those in need.</li>
                        <li className="mt-2 mb-2 ml-1 mr-1">The Qurbani management partners are completely shariah compliant and headed by Islamic Scholars / Ulema.</li>
                        <li className="mt-2 mb-2 ml-1 mr-1">I (user) hereby declare that the amount I am using to purchase udhiyah / qurbani is from halal sources.</li>
                    </ul>
                </Grid>
            </Grid>
        </>
    )
})

export default TermCondition