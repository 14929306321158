const showBackDrop = false;


export const handleBackDrop = (state = showBackDrop, action) => {
    switch (action.type) {
        case "ShowBackDrop":
            state = true;
            return state;
        case "HideBackDrop":
            state = false;
            return state;

        default:
            return state

    }
}

