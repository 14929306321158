import React, { memo } from "react";
import { Footer, Navbar } from "../components";
import { useSelector, useDispatch } from "react-redux";
import { addCart, delCart } from "../redux/action";
import { Link } from "react-router-dom";
import { Box, Button, Grid, Typography } from "@mui/material";
import { Base_Url } from "../context";
import TermCondition from "../components/TermCondition";
import QMLogo from '../assets/QM_icon.webp'
import EmptyCart from "../components/EmptyCart";

const Cart = () => {

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }} >
        <Navbar />
        <div className="container my-3 py-3 flex-1">
          <div className="text-center">
            <img src={QMLogo} width='70px' alt="QM Logo" />
          </div>
          <h1 className="mt-3 text-center">Your Cart</h1>
          <hr />
          <DisplayCart />
        </div>
        <Footer />
      </Box>
    </>
  );
};

export default Cart;

const ShowCart = () => {
  const state = useSelector((state) => state.handleCart);
  console.log(state)
  const configuration = useSelector((configuration) => configuration.handleConfiguration);
  const dispatch = useDispatch();

  const addItem = (product) => {
    dispatch(addCart(product));
  };
  const removeItem = (product) => {
    dispatch(delCart(product));
  };
  let subtotal = 0;
  let totalItems = 0;
  state.map((item) => {
    return (subtotal += item.price * item.qty);
  });

  state.map((item) => {
    return (totalItems += item.qty);
  });
  return (
    <>
      {
        (configuration && configuration.IsQurbani === "ON") ?
          <TermCondition />
          : null
      }
      <section className="h-100 gradient-custom">
        <div className="container py-5">
          <div className="row d-flex justify-content-center my-4">
            <div className="col-md-8">
              <Typography component="div" className="card mb-4" sx={{ minHeight: { lg: '262px' } }}>
                <div className="card-header py-3" style={{borderRadius : '16px'}}>
                  <h5 className="mb-0 black-font" style={{ fontWeight: 700 }}>Item List</h5>
                </div>
                <div className="card-body">
                  {state.map((item, index) => {
                    return (
                      <Box variant={'div'} key={index} className="card-hr">
                        <Grid container>
                          <Grid xs={12} md={12} display={'flex'} justifyContent={'space-between'} alignItems={'end'}>
                            <p className="black-font">
                              <strong>{item.title}</strong>
                            </p>
                            <div className="bg-image rounded" data-mdb-ripple-color="light" >
                              <img
                                src={`${Base_Url}get/tbn_${item.image}`}
                                alt={item.title}
                                width={'auto'}
                                height={75}
                              />
                            </div>
                          </Grid>
                          <Grid xs={12} sm={12} md={12} sx={{ display: { xs: 'flex', md: 'flex' }, justifyContent: 'space-between' }}>
                            <p className="text-start text-md-center black-font">
                              <strong>
                                <span className="text-muted">{item.qty}</span>
                                x ${item.price}
                              </strong>
                            </p>
                            <div className="d-flex" style={{ maxWidth: "300px", alignItems: 'center' }}>
                              <button className="btn px-3" onClick={() => { removeItem(item); }} style={{ background: 'none' }} >
                                <i className="fas fa-minus"></i>
                              </button>
                              <p className="mx-3 mb-0 black-font">{item.qty}</p>
                              <button className="btn px-3" onClick={() => { addItem(item) }} style={{ background: 'none' }} >
                                <i className="fas fa-plus"></i>
                              </button>
                            </div>
                          </Grid>
                        </Grid>
                        <hr className="my-4" />
                      </Box>
                    );
                  })}
                </div>
              </Typography>
            </div>
            <div className="col-md-4">
              <div className="card mb-4">
                <div className="card-header py-3 bg-light black-font" style={{borderRadius : '16px'}}>
                  <h5 className="mb-0">Order Summary</h5>
                </div>
                <div className="card-body">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 pb-0 black-font">
                      Products ({totalItems})<span>${Math.round(subtotal)}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 mb-3 black-font">
                      <div>
                        <strong>Total amount</strong>
                      </div>
                      <span>
                        <strong>${Math.round(subtotal)}</strong>
                      </span>
                    </li>
                  </ul>

                  <Link to="/checkout"
                    style={{ textDecoration: 'none' }} >
                    <Button className="btn-block" variant="contained" sx={{ textTransform: 'capitalize', fontSize: { xs: '13px', md: '14px' }, bgcolor: '#000000', borderRadius: '8px', ":hover": { bgcolor: "#EA6724" } }} >
                      Accept & Continue
                    </Button>
                  </Link>
                  <Link to="/" style={{ textDecoration: 'none' }} >
                    <Button className="btn-block" variant="contained" sx={{ mt: 1, textTransform: 'capitalize', fontSize: { xs: '13px', md: '14px' }, bgcolor: '#000000', borderRadius: '8px', ":hover": { bgcolor: "#EA6724" } }}>
                      Continue Shopping
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const DisplayCart = memo(() => {
  const cartState = useSelector((state) => state.handleCart);
  return (
    <>
      {cartState.length > 0 ? <ShowCart /> : <EmptyCart />}
    </>
  )
})
