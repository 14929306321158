import React, { useEffect, useState } from 'react';
import Carousel from 'react-material-ui-carousel'
import { Paper, Grid, Box, Skeleton } from '@mui/material'
// import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import banner_1 from '../assets/banner_1.webp';
import banner_2 from '../assets/banner_2.webp';
import banner_3 from '../assets/banner_3.webp';
import banner_4 from '../assets/banner_4.webp';
import banner_5 from '../assets/banner_5.webp';
import banner_6 from '../assets/banner_6.webp';
import banner_8 from '../assets/banner_8.webp';
import banner_9 from '../assets/banner_9.webp';
import banner_10 from '../assets/banner_10.webp';
import banner_11 from '../assets/banner_11.webp';
import banner_12 from '../assets/banner_12.webp';
import banner_13 from '../assets/banner_13.webp';
import banner_14 from '../assets/banner_14.webp';
import banner_15 from '../assets/banner_15.webp';
import banner_16 from '../assets/banner_16.webp';
import banner_17 from '../assets/banner_17.webp';





function CauroselSilder(props) {
    const configuration = useSelector((configuration) => configuration.handleConfiguration);
    const [items, setItems] = useState([]);
    useEffect(() => {
           getConfigurationDetails()
    }, [configuration])

    const getConfigurationDetails = () => {
        if (configuration != null && configuration != undefined && configuration.IsQurbani === "ON") {
            setItems([
                {
                    name: "Waqf Qurbani",
                    subtitle: `Udhiyah | Eid ul Adha ${new Date().getFullYear()}`,
                    image: banner_8,
                    description: `لَن يَنَالَ ٱللَّهَ لُحُومُهَا وَلَا دِمَآؤُهَا وَلَـٰكِن يَنَالُهُ ٱلتَّقۡوَىٰ مِنكُمۡ‌ۚ كَذَٲلِكَ سَخَّرَهَا لَكُمۡ لِتُكَبِّرُواْ ٱللَّهَ عَلَىٰ مَا هَدَٮٰكُمۡ‌ۗ وَبَشِّرِ ٱلۡمُحۡسِنِينَ`,
                    ref: 'سورة الحج - الآية 37',
                    fontfamily: "PDMS_Saleem_QuranFont",
                    fontsize: '45px', xfontsize: '25px', letterSpacing: 'normal', lineHeight: '4rem', xlineHeight: 'normal'
                },
                {
                    name: "Waqf Qurbani",
                    subtitle: `Udhiyah | Eid ul Adha ${new Date().getFullYear()}`,
                    image: banner_9,
                    description: `Their flesh and their blood reach not Allah, but the devotion from you reacheth Him. Thus have We made them subject unto you that ye may magnify Allah that He hath guided you. And give good tidings (O Muhammad) to the good.`,
                    ref: "[Sura Al-Hajj - (Aya-37)]", letterSpacing: 'auto', lineHeight: '2rem'
                },
                {
                    name: "Waqf Qurbani",
                    subtitle: `Udhiyah | Eid ul Adha ${new Date().getFullYear()}`,
                    image: banner_10,
                    description: `الله کو نہ ان کا گوشت اور نہ ان کا خون پہنچتا ہے البتہ تمہاری پرہیز گاری اس کے ہاں پہنچتی ہے اسی طرح انہیں تمہارے تابع کر دیا تاکہ تم الله کی بزرگی بیان کرو اس پر کہ اس نے تمہیں ہدایت کی اور نیکوں کو خوشخبری سنا دو `,
                    ref: "سورة الحج - آیت  ۳۷",
                    fontfamily: "PDMS_NastaliqNafees_iphone",
                    fontsize: '30px', letterSpacing: 'auto', lineHeight: '3rem'
                },
                {
                    name: "Waqf Qurbani",
                    subtitle: `Udhiyah | Eid ul Adha ${new Date().getFullYear()}`,
                    image: banner_11,
                    description: `PakData will be continuing the Waqf Qurbani Program in preparation for Eid-ul-Adha ${new Date().getFullYear()}. It is ensured that the deserving families are prioritized for Qurbani meat distribution, and those families receive quality meat through stringent animal selection standards, and that animals are transported and butchered in a humane and Shariah-compliant manner.`,
                    letterSpacing: 'auto', lineHeight: 'auto'
                },
                {
                    name: "Waqf Qurbani",
                    subtitle: `Udhiyah | Eid ul Adha ${new Date().getFullYear()}`,
                    image: banner_12,
                    description: `PakData will be continuing the Waqf Qurbani Program in preparation for Eid-ul-Adha ${new Date().getFullYear()}. It is ensured that the deserving families are prioritized for Qurbani meat distribution, and those families receive quality meat through stringent animal selection standards, and that animals are transported and butchered in a humane and Shariah-compliant manner.`,
                    letterSpacing: 'auto', lineHeight: '2rem'
                },
                {
                    name: "Waqf Qurbani",
                    subtitle: `Udhiyah | Eid ul Adha ${new Date().getFullYear()}`,
                    image: banner_13,
                    description: `PakData will be continuing the Waqf Qurbani Program in preparation for Eid-ul-Adha ${new Date().getFullYear()}. It is ensured that the deserving families are prioritized for Qurbani meat distribution, and those families receive quality meat through stringent animal selection standards, and that animals are transported and butchered in a humane and Shariah-compliant manner.`,
                    letterSpacing: 'auto', lineHeight: '2rem'
                },
                {
                    name: "Waqf Qurbani",
                    subtitle: `Udhiyah | Eid ul Adha ${new Date().getFullYear()}`,
                    image: banner_14,
                    description: `PakData will be continuing the Waqf Qurbani Program in preparation for Eid-ul-Adha ${new Date().getFullYear()}. It is ensured that the deserving families are prioritized for Qurbani meat distribution, and those families receive quality meat through stringent animal selection standards, and that animals are transported and butchered in a humane and Shariah-compliant manner.`,
                    letterSpacing: 'auto', lineHeight: '2rem'
                },
                {
                    name: "Waqf Qurbani",
                    subtitle: `Udhiyah | Eid ul Adha ${new Date().getFullYear()}`,
                    image: banner_15,
                    description: `PakData will be continuing the Waqf Qurbani Program in preparation for Eid-ul-Adha ${new Date().getFullYear()}. It is ensured that the deserving families are prioritized for Qurbani meat distribution, and those families receive quality meat through stringent animal selection standards, and that animals are transported and butchered in a humane and Shariah-compliant manner.`,
                    letterSpacing: 'auto', lineHeight: '2rem'
                },
                {
                    name: "Waqf Qurbani",
                    subtitle: `Udhiyah | Eid ul Adha ${new Date().getFullYear()}`,
                    image: banner_16,
                    description: `PakData will be continuing the Waqf Qurbani Program in preparation for Eid-ul-Adha ${new Date().getFullYear()}. It is ensured that the deserving families are prioritized for Qurbani meat distribution, and those families receive quality meat through stringent animal selection standards, and that animals are transported and butchered in a humane and Shariah-compliant manner.`,
                    letterSpacing: 'auto', lineHeight: '2rem'
                },
                {
                    name: "Waqf Qurbani",
                    subtitle: `Udhiyah | Eid ul Adha ${new Date().getFullYear()}`,
                    image: banner_17,
                    description: `PakData will be continuing the Waqf Qurbani Program in preparation for Eid-ul-Adha ${new Date().getFullYear()}. It is ensured that the deserving families are prioritized for Qurbani meat distribution, and those families receive quality meat through stringent animal selection standards, and that animals are transported and butchered in a humane and Shariah-compliant manner.`,
                    letterSpacing: 'auto', lineHeight: '2rem'
                },
                {
                    name: "Waqf Qurbani",
                    subtitle: `Udhiyah | Eid ul Adha ${new Date().getFullYear()}`,
                    image: banner_5,
                    description: `PakData will be continuing the Waqf Qurbani Program in preparation for Eid-ul-Adha ${new Date().getFullYear()}. It is ensured that the deserving families are prioritized for Qurbani meat distribution, and those families receive quality meat through stringent animal selection standards, and that animals are transported and butchered in a humane and Shariah-compliant manner.`,
                    letterSpacing: 'auto', lineHeight: '2rem'
                },
                {
                    name: "Waqf Qurbani",
                    subtitle: `Udhiyah | Eid ul Adha ${new Date().getFullYear()}`,
                    image: banner_6,
                    description: `PakData will be continuing the Waqf Qurbani Program in preparation for Eid-ul-Adha ${new Date().getFullYear()}. It is ensured that the deserving families are prioritized for Qurbani meat distribution, and those families receive quality meat through stringent animal selection standards, and that animals are transported and butchered in a humane and Shariah-compliant manner.`,
                    letterSpacing: 'auto', lineHeight: '2rem'
                }
            ])
        }
        if (configuration != null && configuration != undefined && configuration.IsQurbani === "OFF") {
            setItems([
                {
                    name: "Shop with PakStore",
                    image: banner_1,
                    description: "PakStore is one of the best online store. We are selling online Prayer Caps, Prayer Mats, Fragrance, Bags, Watches, Garments and more at Wholesale Rates in all over Pakistan."
                },
                {
                    name: "Shop with PakStore",
                    image: banner_2,
                    description: "PakStore is one of the best online store. We are selling online Prayer Caps, Prayer Mats, Fragrance, Bags, Watches, Garments and more at Wholesale Rates in all over Pakistan."
                },
                {
                    name: "Shop with PakStore",
                    image: banner_4,
                    description: "PakStore is one of the best online store. We are selling online Prayer Caps, Prayer Mats, Fragrance, Bags, Watches, Garments and more at Wholesale Rates in all over Pakistan."
                }
            ])
        }
    }

    return (
        <Grid container justifyContent={'center'} sx={{ height: { xs: '350px', md: '572px' }, width : '100%' }}>
            {(items.length > 0) ?
                <Carousel animation='fade' sx={{ width: '100%', justifyContent: 'center' }} cycleNavigation={true} navButtonsAlwaysVisible={true} autoPlay={true} duration={500} interval={3000} stopAutoPlayOnHover={true} >
                    {items.map((item, i) => <Item key={i} item={item} />)}
                </Carousel>
                :
                <Skeleton variant='rectangular' sx={{ height: { xs: '350px', md: '572px' }, width : '100%' }} />
            }
        </Grid>
    )
}

function Item(props) {
    return (
        <Paper sx={{ backgroundImage: `url(${props.item.image})`, height: { xs: '350px', md: '572px' }, width: '100%', backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
            <Grid container height={'100%'} sx={{ alignItems: { xs: 'baseline', md: 'center', background: 'rgba(0,0,0,0.5)' } }} justifyContent={'center'}>
                <Grid item xs={5} sx={{ display: { xs: 'block', md: 'none' } }} />
                <Grid item xs={10} md={6} paddingRight={4} paddingLeft={4} >
                    <Box component={'div'} className='text-center' >
                        <Box component='h1' sx={{ display: { xs: 'none', md: 'block' }, fontSize: '50px', fontWeight: 500 }} className='orangeText'>{props.item.name}</Box>
                        <Box component='h4' sx={{ display: { xs: 'block', md: 'none' } }} className='orangeText'>{props.item.name}</Box>
                        <Box component='h3' sx={{ display: { xs: 'none', md: 'block' }, color: '#ea6723' }}>{props.item.subtitle}</Box>
                        <Box component='h6' sx={{ display: { xs: 'block', md: 'none' }, color: '#ea6723' }}>{props.item.subtitle}</Box>
                        <Box component='h5'
                            sx={{
                                display: { xs: 'none', md: 'block' },
                                fontSize: props.item.fontsize ? props.item.fontsize : '25px', fontWeight: 500, color: 'white', mt: 2, lineHeight: props.item.lineHeight, letterSpacing: props.item.letterSpacing,
                                fontFamily: props.item.fontfamily ? props.item.fontfamily : 'Roboto'
                            }}  >{props.item.description}</Box>
                        <Box component='p' sx={{
                            display: { xs: 'block', md: 'none' }, color: 'white', fontSize: props.item.xfontsize ? props.item.xfontsize : '14px',
                            fontFamily: props.item.fontfamily ? props.item.fontfamily : 'Roboto', letterSpacing: props.item.letterSpacing
                        }}  >{props.item.description}</Box>
                        <Box component='h5' sx={{ display: props.item.ref ? 'block' : 'none', fontSize: props.item.xfontsize ? props.item.xfontsize : '13px', fontWeight: 500, color: 'white', fontFamily: props.item.fontfamily ? props.item.fontfamily : 'Roboto' }}  >{props.item.ref}</Box>
                    </Box>
                </Grid>

            </Grid>
        </Paper>
    )
}

export default CauroselSilder;
