import { Grid, Typography, FormControl, MenuItem, Select, InputLabel, Button } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import { Base_Url } from '../context';
import SnackBar from "./SnackBar";

const HblSetting = () => {
    const [snackBar, setSnackBar] = useState(false);
    const [messageDetails, setMessageDetails] = useState({
        message: "Setting Save Successfully. !",
        meesageType: "success"
    })
    const [gatewayConfiguration, setGatewayConfiguration] = useState({
        version: 'NV',
        qurbani : 'ON'
    })
    const SaveGatewayConfiguration = async () => {
        if (gatewayConfiguration.version !== null && gatewayConfiguration.version !== undefined && gatewayConfiguration.version !== "") {
            const body = {
                'feildName': 'GatewayVersion',
                'value': gatewayConfiguration.version
            }
            const response = await axios.post(`${Base_Url}configuraiton/gateway`, body);
            if (response.status === 200) {
                setMessageDetails(preValue => { return { ...preValue, message: "Setting Save Successfully. !", meesageType: "success" } });
                setSnackBar(true)
            }
        }
        else {
            setMessageDetails(preValue => { return { ...preValue, message: "Please select Gateway version. !", meesageType: "error" } });
            setSnackBar(true)
        }
    }

    const SaveQurbaniConfiguration = async () => {
        if (gatewayConfiguration.qurbani !== null && gatewayConfiguration.qurbani !== undefined && gatewayConfiguration.qurbani !== "") {
            const body = {
                'feildName': 'IsQurbani',
                'value': gatewayConfiguration.qurbani
            }
            console.log(body)
            const response = await axios.post(`${Base_Url}configuraiton/gateway`, body);
            if (response.status === 200) {
                // setMessageDetails(preValue => { return { ...preValue, message: "Setting Save Successfully. !", meesageType: "success" } });
                // setSnackBar(true)
            }
        }
        else {
            // setMessageDetails(preValue => { return { ...preValue, message: "Please select Gateway version. !", meesageType: "error" } });
            // setSnackBar(true)
        }
    }

    return (
        <>
            <Grid container rowSpacing={2}>

                    <Grid item xs={12}>
                        <Typography component="div" variant='h5'>Payment Gateway Settings</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={2} >
                        <FormControl variant="outlined" sx={{ mt: 2, maxWidth: '100%', minWidth: '100%' }} required>
                            <InputLabel id="hbl-gateway-label">HBL Gateway Version</InputLabel>
                            <Select value={gatewayConfiguration.version} name="version"
                                labelId="hbl-gateway-label" label="HBL Gateway Version" onChange={(event) => { setGatewayConfiguration(preValues => { return { ...preValues, [event.target.name]: event.target.value } }) }}
                                id="hbl-gateway">
                                <MenuItem value=''>
                                    <em>Select Version</em>
                                </MenuItem>
                                <MenuItem value="NV">New Version</MenuItem>
                                <MenuItem value="OV">Old Version</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography component='div' ><Button variant='contained' onClick={SaveGatewayConfiguration} >Save</Button></Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography component="div" variant='h5'>Enable Qurbani</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={2} >
                        <FormControl variant="outlined" sx={{ mt: 2, maxWidth: '100%', minWidth: '100%' }} required>
                            <InputLabel id="hbl-gateway-label">Enable Qurbani</InputLabel>
                            <Select value={gatewayConfiguration.qurbani} name="qurbani"
                                labelId="hbl-gateway-label" label="Enable Qurbani" onChange={(event) => { setGatewayConfiguration(preValues => { return { ...preValues, [event.target.name]: event.target.value } }) }}
                                id="hbl-gateway">
                                <MenuItem value=''>
                                    <em>Select Version</em>
                                </MenuItem>
                                <MenuItem value="ON">Enable</MenuItem>
                                <MenuItem value="OFF">Disable</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography component='div' ><Button variant='contained' onClick={SaveQurbaniConfiguration} >Save</Button></Typography>
                    </Grid>
                    <SnackBar message={messageDetails.message} messageType={messageDetails.meesageType} open={snackBar} setOpen={setSnackBar} />
            </Grid>
        </>
    )
}

export default HblSetting