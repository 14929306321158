// For Add Item to Cart
export const addCart = (product) =>{
    return {
        type:"ADDITEM",
        payload:product
    }
}

// For Delete Item to Cart
export const delCart = (product) =>{
    return {
        type:"DELITEM",
        payload:product
    }
}

// For display backdrop
export const showBackDrop = () =>{
    return {
        type:"ShowBackDrop",
    }
}

export const hideBackDrop = () =>{
    return {
        type:"HideBackDrop",
    }
}

export const showSnackBar = (message) =>{
    return {
        type:"ShowSnackBar",
        payload : message
    }
}

export const hideSnackBar = (messageData) =>{
    return {
        type:"HideSnackBar",
        payload : messageData
    }
}

export const getConfigurationDetails = (configData) =>{
    return {
        type:"GetConfiguration",
        payload : configData
    }
}

export const insertProducts = (products) =>{
    return {
        type:"ADDPRODUCTS",
        payload : products
    }
}