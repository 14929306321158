import { Typography } from "@mui/material";
import HblSetting from "./HblSetting";


function Settings(props) {
  const { children, value, index, ...other } = props;
  return (
    <>
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        <Typography variant="h4" className="text-center mt-5" >Settings</Typography>
        <HblSetting />
      </div>
    </>
  )
}

export default Settings