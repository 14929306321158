import React from 'react';
import { useSelector } from "react-redux";
import { TextField } from "@mui/material";


const QurbaniInformation = () => {

    const state = useSelector((state) => state.handleCart);

    return (
        <div className="row g-1">
            {
                state.map((item, index) => {
                    return (
                        (item.qty === 1 && item.title !== "Cow Qurbani") ?
                            <div className="col-12 blank-font" key={index}>
                                <label name="qurbaniInfo" className="form-label mt-2 black-font">
                                    Name of Person for {item.title}
                                    <span className="text-muted"></span>
                                </label>

                                <TextField
                                    type="text"
                                    label={item.title + " # " + item.qty}
                                    id={item.title + "-1"}
                                    name={item.title}
                                    fullWidth={true}
                                    color='success'
                                    
                                />
                            </div>
                            :
                            Array.from({ length: item.title === "Cow Qurbani" ? item.qty * 7 : item.qty }, (el, i) => (
                                <div className="col-12 black-font" key={i}>
                                    {(i === 0) ?
                                        <label name="qurbaniInfo" className="form-label mt-2 black-font" >
                                            Name of Persons for {item.title}
                                            <span className="text-muted"></span>
                                        </label> : ""
                                    }
                                    {(item.title === "Cow Qurbani" && i % 7 === 0) ?
                                        <p className="black-font">Name of Person for Cow # {i === 0 ? i + 1 : (i + 7) / 7} </p>
                                        :
                                        ""
                                    }

                                    <TextField sx={{ mb: 2 }}
                                        type="text"
                                        label={item.title === "Cow Qurbani" ? (i + 1) % 7 !== 0 ? "Cow Part # " + (i + 1) % 7 : "Cow Part # " + 7 : item.title + " # " + (i + 1)}
                                        id={item.title + "-" + (i + 1)}
                                        name={item.title}
                                        fullWidth={true}
                                        color='success'
                                    />
                                </div>
                            ))
                    )
                })
            }
        </div>
    )
}

export default QurbaniInformation