import React from "react";
import { useSelector } from "react-redux";
import { Box, Container, Skeleton } from "@mui/material";
import CauroselSilder from "./Caurosel";


const Home = () => {
  const configuration = useSelector((configuration) => configuration.handleConfiguration);
  return (
    <>
      <CauroselSilder />
      {(configuration === null || configuration === undefined) ?
        <Skeleton variant="text" width={'400px'} height={'100px'} />
        : null
      }
      {(configuration && configuration.IsQurbani && configuration.IsQurbani === "ON") ?
        <Container>
          <Box component="div" mt={5}>
            <div className="container">
              <h2 className=" text-center mt-4" style={{ fontWeight: "bolder" }}>Waqf Qurbani with PakData</h2>
              <p className="card-text fs-5  text-center mt-4 mb-4" >PakData will be continuing the Waqf Qurbani Program in preparation for Eid-ul-Adha {new Date().getFullYear()}. It is ensured that the deserving families are prioritized for Qurbani meat distribution, and those families receive quality meat through stringent animal selection standards, and that animals are transported and butchered in a humane and Shariah-compliant manner.</p>
              <p style={{ paddingTop: "10px", fontFamily: 'PDMS_Saleem_QuranFont', fontSize: '30px', direction: 'rtl', wordSpacing: '3px', letterSpacing: '1px', textAlign: 'center' }}> ستواصل PakData مشروع الأضحية (للوقف) استعدادًا لعيد الأضحى {new Date().getFullYear()}. ونؤكد أن العائلات المستحقة لها الأولوية في توزيع لحوم الأضحية، وأن تلك العائلات تحصل على اللحم الجيد بسبب معايير صارمة لاختيار الحيوانات، كما نؤكد أن نقل الحيوانات وذبحها يتم بطريقة إنسانية ومتوافقة مع الشريعة الإسلامية.</p>
            </div>
          </Box>
        </Container>
        :
        null
      }
      {(configuration && configuration.IsQurbani && configuration.IsQurbani === "OFF") ?
        <Box component={'div'} mt={5}>
          <Box component={'h1'} className="text-center">
            Shop Now
          </Box>
        </Box>
        :
        null
      }
    </>
  );
};

export default Home;
