import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Table, TableBody, TableCell, TableHead, TableRow, TableContainer, tableCellClasses, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import { styled } from 'styled-components';

const StyledTableRow = styled(TableRow)({
    '&:nth-of-type(odd)': {
        backgroundColor: "white",
    },
    '&:nth-of-type(even)': {
        backgroundColor: "#ecf6ff",
    },
    textTransform: 'capitalize'
});

const StyledTableCell = styled(TableCell)({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#283b60',
        color: 'whitesmoke',
        fontSize: '16px',
        fontWeight: 600
    }
});

const StyledSecondaryTableCell = styled(TableCell)({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#46869e',
        color: 'whitesmoke',
        fontSize: '15px',
        fontWeight: 600
    }
});


export default function UserDetailDialog(props) {

    const { open, setOpen, userOrder } = props;

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth='lg' fullWidth={true}
            >
                <DialogTitle id="alert-dialog-title" sx={{ fontSize: '22px', fontWeight: 600 }}>
                    Customer Details
                </DialogTitle>
                <hr />
                <DialogContent>
                    <Table sx={{width : 'fit-content'}}>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ borderBottom: 'none', padding: '5px' }}>
                                    <Typography>Name : </Typography>
                                </TableCell>
                                <TableCell sx={{ borderBottom: 'none', padding: '5px' }}>
                                    <Typography>{userOrder && userOrder.user && userOrder.user.Name ? userOrder.user.Name : "000"} </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ borderBottom: 'none', padding: '5px' }}>
                                    <Typography> Email :</Typography>
                                </TableCell>
                                <TableCell sx={{ borderBottom: 'none', padding: '5px' }}>
                                    <Typography>{userOrder && userOrder.user && userOrder.user.Uid ? userOrder.user.Uid : "000"} </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ borderBottom: 'none', padding: '5px' }}>
                                    <Typography>Country :</Typography>
                                </TableCell>
                                <TableCell sx={{ borderBottom: 'none', padding: '5px' }}>
                                    <Typography>{userOrder && userOrder.user && userOrder.user.Country ? userOrder.user.Country : "000"} </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ borderBottom: 'none', padding: '5px' }}>
                                    <Typography>State :</Typography>
                                </TableCell>
                                <TableCell sx={{ borderBottom: 'none', padding: '5px' }}>
                                    <Typography>{userOrder && userOrder.user && userOrder.user.State ? userOrder.user.State : "000"} </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ borderBottom: 'none', padding: '5px' }}>
                                    <Typography>Phone :</Typography>
                                </TableCell>
                                <TableCell sx={{ borderBottom: 'none', padding: '5px' }}>
                                    <Typography>{userOrder && userOrder.user && userOrder.user.Phone ? userOrder.user.Phone : "000"} </Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <TableContainer component={Paper} >
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell colSpan={8} align='center'>Order Details</StyledTableCell>
                                </TableRow>
                                <TableRow>
                                    <StyledSecondaryTableCell align='center' >S.no</StyledSecondaryTableCell>
                                    <StyledSecondaryTableCell align='center' >Date</StyledSecondaryTableCell>
                                    <StyledSecondaryTableCell align='center' >OrderId</StyledSecondaryTableCell>
                                    <StyledSecondaryTableCell align='center' >Type</StyledSecondaryTableCell>
                                    <StyledSecondaryTableCell align='center' >Status</StyledSecondaryTableCell>
                                    <StyledSecondaryTableCell align='center' >TotalAmount</StyledSecondaryTableCell>
                                    <StyledSecondaryTableCell align='center' >IndividualParts</StyledSecondaryTableCell>
                                </TableRow>
                                {
                                    (userOrder && userOrder.orders) ?
                                        userOrder.orders.map((item, index) => {
                                            return (

                                                <StyledTableRow key={index}>
                                                    <TableCell sx={{ borderBottom: 'none', padding: '5px', }} align='center'>{index + 1}</TableCell>
                                                    <TableCell sx={{ borderBottom: 'none', padding: '5px', }} align='center' >
                                                        {item.Date}
                                                    </TableCell>
                                                    <TableCell sx={{ borderBottom: 'none', padding: '5px', }} align='center' >
                                                        {item.Id}
                                                    </TableCell>
                                                    <TableCell sx={{ borderBottom: 'none', padding: '5px', }} align='center'>{item.Type}</TableCell>
                                                    <TableCell sx={{ borderBottom: 'none', padding: '5px', }} align='center'>{item.Status}</TableCell>
                                                    <TableCell sx={{ borderBottom: 'none', padding: '5px', }} align='center'>$ {item.TotalAmout}</TableCell>
                                                    <TableCell sx={{ borderBottom: 'none', padding: '5px', }} align='center'>{item.IndividualParts}</TableCell>
                                                </StyledTableRow>
                                            )
                                        })
                                        : ""
                                }
                            </TableHead>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                </DialogActions>
            </Dialog>
        </div>
    );
}