import React, {memo} from "react";
import { Box, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { addCart, delCart } from "../redux/action";

const CartBottom = memo((props) => {
    const cardState = useSelector(state => state.handleCart);
    const dispatch = useDispatch();

    const addProduct = (product) => {
        dispatch(addCart(product))
    }

    const removeItem = (product) => {
        dispatch(delCart(product));
    };

    const getProductQuantity = (productId) => {
        // Find the quantity of a specific product in the cart
        const cartProduct = cardState.find((item) => item.Id === productId);
        return cartProduct ? cartProduct.qty : 0;
    };

    return (
        <>
            <div>
                <div className="card-body pl-4 black-font" style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'end' }}>
                    <p style={{ fontSize: { xs: '15px', md: '20px' }, fontWeight: 500, marginBottom: '5px' }}>US ${props.product.price}</p>
                    {
                        (cardState.length > 0 && cardState.find((x) => x.Id === props.product.Id)) ?
                            <div
                                className="d-flex"
                                style={{ maxWidth: "300px" }}
                            >
                                <Button variant="contained" sx={{ height: { xs: '30px' }, marginTop: { xs: '0px', md: '5px' }, minWidth: 'fit-content', bgcolor: '#EA6724', ":hover": { bgcolor: "#000000" }, borderRadius: '5px' }}
                                    onClick={() => {
                                        removeItem(props.product);
                                    }}
                                >
                                    <i className="fas fa-minus"></i>
                                </Button>

                                <Box variant='p' className="mx-4 black-font" sx={{ marginBottom: '5px', marginTop: { xs: '0px', md: '5px' } }}>
                                    {getProductQuantity(props.product.Id)}
                                </Box>

                                <Button variant="contained" sx={{ height: { xs: '30px' }, marginTop: { xs: '0px', md: '5px' }, minWidth: 'fit-content', bgcolor: '#EA6724', ":hover": { bgcolor: "#000000" }, borderRadius: '5px' }}
                                    onClick={() => {
                                        addProduct(props.product);
                                    }}
                                >
                                    <i className="fas fa-plus"></i>
                                </Button>
                            </div>
                            :
                            <Button variant="contained" sx={{ height: { xs: '30px', md: '35px' }, width: { xs: '118px', md: '130px' }, fontSize: { xs: '13px', md: '13px' }, bgcolor: '#000000', borderRadius: '9px', ":hover": { bgcolor: "#EA6724" } }} onClick={() => addProduct(props.product)}>
                                Add to Cart
                            </Button>
                    }
                </div>
            </div>
        </>
    )
})

export default CartBottom