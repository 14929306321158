import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Home, Products, AboutPage, ContactPage, Cart, Login, Register, Checkout, PageNotFound, AdminPage, ResponsePage, HelloPage } from "./pages"
import { getConfigurationDetails } from './redux/action';
import { Base_Url } from './context';
import axios from 'axios';

const App = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    GetGatewayConfiguration()
  }, []);

  const GetGatewayConfiguration = async () => {
    const response = await axios.post(`${Base_Url}configuraiton/gatewayList`);
    if (response.status === 200) {
      dispatch(getConfigurationDetails(response.data));
    }
  }

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/product" element={<Products />} />
          {/* <Route path="/product/:id" element={<Product />} /> */}
          <Route path="/about" element={<AboutPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="*" element={<PageNotFound />} />
          <Route path="/product/*" element={<PageNotFound />} />
          <Route path="/response/:id" element={<ResponsePage />} />
          <Route path="/hello" element={<HelloPage />} />
          <Route path="/admin" element={<AdminPage />} />
        </Routes>
      </BrowserRouter>
    </>
  )
}

export default App;