import React from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const ImageModal = (props) => {
    const { open, setOpen, dialogData } = props;

    const handleClose = () => {
        setOpen(false);
    }
  return (
    <Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    maxWidth='lg' fullWidth={true}
>
    <DialogTitle id="alert-dialog-title" sx={{ fontSize: '22px', fontWeight: 600, textAlign : 'center' }}>
        {dialogData.title}
    </DialogTitle>
    <hr />
    <DialogContent sx={{display : 'flex', justifyContent : 'center'}}>
        <img src={dialogData.image} alt='Modal Img' width={'auto'} style={{maxWidth : '100%'}} />
    </DialogContent>
    <DialogActions>
    </DialogActions>
</Dialog>
  )
}

export default ImageModal