import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AdminOrder from './AdminOrder';
import ProductionQuantityLimitsOutlinedIcon from '@mui/icons-material/ProductionQuantityLimitsOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import SellIcon from '@mui/icons-material/Sell';
import HomeIcon from '@mui/icons-material/Home';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import Drawer from '@mui/material/Drawer';
import DashboardTab from './Dashboard';
import ProductTab from './Product';
import ContentTab from './Content';
import UserTab from './User';
import CssBaseline from '@mui/material/CssBaseline';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useNavigate } from 'react-router-dom';
import Settings from './Settings';
import ShandoorTab from './Shandoor';

const drawerWidth = 205;

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
          <div>{children}</div>
      )}
    </div>
  );
}

export default function VerticalTabs() {
  const [value, setValue] = React.useState(1);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigate = useNavigate();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <>
      <Box
        sx={{ flexGrow: 1, bgcolor: '#283b60', display: 'flex', height: '100%' }}
      >
        <CssBaseline />
        <AppBar

          color=''
          position="fixed"
          sx={{
            width: { md: `calc(100% - ${drawerWidth - 5}px)` }, backgroundColor: '#283b60', color: '#fff',
            ml: { sm: `${drawerWidth}px` },
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { md: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap >
              Welcome to Admin Panel
            </Typography>
          </Toolbar>
        </AppBar>
        <Box
          sx={{ height: '100%' }}
        >
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: 'none', md: 'block' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '202px', backgroundColor: '#283b60 !important', color: 'white' },
            }}
            open={true}
          >
            <Tabs
              orientation="vertical"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              sx={{ borderRight: 1, minWidth: '200px', borderColor: 'divider', height: '100%', overflowY: 'hidden', }}
            >
              <Tab sx={{ color: 'white', justifyContent: 'flex-start' }} icon={<DashboardIcon />} iconPosition='start' label="Dashboard" {...a11yProps(0)} />

              <Tab sx={{ color: 'white', justifyContent: 'flex-start' }} icon={<SellIcon />} iconPosition='start' label="Order" {...a11yProps(1)} />

              <Tab sx={{ color: 'white', justifyContent: 'flex-start' }} icon={<ProductionQuantityLimitsOutlinedIcon />} iconPosition='start' label="Product" {...a11yProps(2)} />

              <Tab sx={{ color: 'white', justifyContent: 'flex-start' }} icon={<FolderCopyIcon />} iconPosition='start' label="Content" {...a11yProps(3)} />

              <Tab sx={{ color: 'white', justifyContent: 'flex-start' }} icon={<AccountCircleOutlinedIcon />} iconPosition='start' label="User" {...a11yProps(4)} />

              <Tab sx={{ color: 'white', justifyContent: 'flex-start' }} icon={<HomeIcon />} iconPosition='start' label="Home" onClick={() => { navigate('/') }} />

              <Tab sx={{ color: 'white', justifyContent: 'flex-start' }} icon={<SettingsApplicationsIcon />} iconPosition='start' label="Settings" {...a11yProps(6)} />

              <Tab sx={{ color: 'white', justifyContent: 'flex-start' }} icon={<SettingsApplicationsIcon />} iconPosition='start' label="Shandoor" {...a11yProps(7)} />

            </Tabs>
          </Drawer>
          <Drawer
            sx={{
              display: { xs: 'block', md: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '202px', backgroundColor: '#283b60 !important', color: 'white' },
            }}
            variant="persistent"
            anchor="left"
            open={mobileOpen}
          >
            <Toolbar sx={{ justifyContent: "end" }}>
              <IconButton onClick={handleDrawerToggle} color='white' sx={{ fontSize: "40px", color: 'white' }}>
                <ChevronLeftIcon color='white' fontSize='25px' />
              </IconButton>
            </Toolbar>
            <Tabs
              orientation="vertical"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              sx={{ borderRight: 1, minWidth: '200px', borderColor: 'divider', height: '100%', overflowY: 'hidden', }}
            >
              <Tab sx={{ color: 'white', justifyContent: 'flex-start' }} icon={<DashboardIcon />} iconPosition='start' label="Dashboard" {...a11yProps(0)} />

              <Tab sx={{ color: 'white', justifyContent: 'flex-start' }} icon={<SellIcon />} iconPosition='start' label="Order" {...a11yProps(1)} />

              <Tab sx={{ color: 'white', justifyContent: 'flex-start' }} icon={<ProductionQuantityLimitsOutlinedIcon />} iconPosition='start' label="Product" {...a11yProps(2)} />

              <Tab sx={{ color: 'white', justifyContent: 'flex-start' }} icon={<FolderCopyIcon />} iconPosition='start' label="Content" {...a11yProps(3)} />

              <Tab sx={{ color: 'white', justifyContent: 'flex-start' }} icon={<AccountCircleOutlinedIcon />} iconPosition='start' label="User" {...a11yProps(4)} />

              <Tab sx={{ color: 'white', justifyContent: 'flex-start' }} icon={<HomeIcon />} iconPosition='start' label="Home" onClick={() => { navigate('/') }} />

              <Tab sx={{ color: 'white', justifyContent: 'flex-start' }} icon={<SettingsApplicationsIcon />} iconPosition='start' label="Settings" {...a11yProps(6)} />

              <Tab sx={{ color: 'white', justifyContent: 'flex-start' }} icon={<SettingsApplicationsIcon />} iconPosition='start' label="Shandoor" {...a11yProps(7)} />
            </Tabs>
          </Drawer>
        </Box>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, float: { md: 'right' }, mt: { xs: 10 }, pl: 2, pr: 1, width: { md: `calc(100% - ${drawerWidth}px)` } }}
      >
        <TabPanel value={value} index={0}><DashboardTab /></TabPanel>
        <TabPanel value={value} index={1}><AdminOrder /></TabPanel>
        <TabPanel value={value} index={2}><ProductTab /></TabPanel>
        <TabPanel value={value} index={3}><ContentTab /></TabPanel>
        <TabPanel value={value} index={4}><UserTab /></TabPanel>
        <TabPanel value={value} index={6}><Settings /></TabPanel>
        <TabPanel value={value} index={7}><ShandoorTab /></TabPanel>
      </Box>
    </>
  );
}