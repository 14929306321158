import { Box, Typography, Button, TextField, Grid, Select, FormControl, MenuItem } from "@mui/material";
import StickyHeadTable from "./Table";
import { useEffect, useState } from "react";
import axios from "axios";
import { Base_Url } from "../context";
import { ExportToExcel } from "./ExportExcel";
import { DateString } from "./DateString";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';


function ShandoorTab(props) {
  const { children, value, index, ...other } = props;
  const [columns] = useState(['S.No', 'Date', 'Name', 'IndividualQurbani', 'Country', 'QurbaniDayTime', 'Amount']);
  const [tableData, setTableData] = useState();
  const [tempTableData, setTemTableData] = useState();

  useEffect(() => {
    getShandoorReport();
  }, []);

  const getShandoorReport = async () => {
    const userList = await axios.post(`${Base_Url}shandoor/report`);
    if(userList.data.length > 0){
      // var updatedData = userList.data.map(item => {
      //   return {
      //     ...item,
      //     Date: DateString(item.createdAt)
      //   };
      // });
      setTableData(userList.data);
    }
  }

  const ExportData = () => {
    const modifiedData = tableData.map(row => {
      delete row.createdAt;
      delete row.updatedAt;
      delete row.orderId;
      delete row.productId;
      return row;
    }
    )
    ExportToExcel(modifiedData, "shandoor", "shandoorReport.xlsx")
  }

  const FilterOrder = async (event) => {
    event.preventDefault();
    var payloadObj = {};
    for (let i = 0; i < event.target.length; i++) {
      if (event.target[i].name !== "" && event.target[i].name !== null && event.target[i].name !== undefined && event.target[i].value !== "") {
        payloadObj[event.target[i].name] = event.target[i].value;
      }
    }
    
    if (payloadObj.fromDate && payloadObj.toDate) {
      
      var splitFromDate = payloadObj.fromDate.split("/");
      var splitToDate = payloadObj.toDate.split("/");
      var fromDate = new Date(payloadObj.fromDate);
      fromDate.setUTCHours(0, 0, 0, 0);
      fromDate.setUTCDate(Number(splitFromDate[1]));
      fromDate.setUTCFullYear(Number(splitFromDate[2]));
      fromDate.setUTCMonth(Number(splitFromDate[0] - 1));
      
      var toDate = new Date(payloadObj.toDate);
      toDate.setUTCHours(23, 59, 59, 999);
      toDate.setUTCDate(Number(splitToDate[1]));
      toDate.setUTCFullYear(Number(splitToDate[2]));
      toDate.setUTCMonth(Number(splitToDate[0] - 1));
      
      payloadObj.fromDate = fromDate;
      payloadObj.toDate = toDate;
    }
    

    const responseData = await axios.post(`${Base_Url}shandoor/filter`, payloadObj);
    console.log(responseData.data)
    if (tempTableData === undefined) {
      setTemTableData(tableData);
    }
    if(!responseData.data.message){
      var updatedData = responseData.data.map(item => {
        return {
          ...item,
          Date: DateString(item.createdAt)
        };
      });
      setTableData(updatedData);
    }
    else{
      setTableData(responseData.data);
    }
  }

  const ClearFilter = () => {
    setTableData(tempTableData);
  }

  return (
    <>
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        <Typography variant="h3" sx={{ fontWeight: 'bolder', fontSize: { xs: '28px', sm: '35px' } }} className="text-start mt-5" >Shandoor Sheet</Typography>
        <Box>
          <Typography component="form" onSubmit={FilterOrder}>

            <Grid container columnGap={2} rowGap={2} mt={5}>
              <Grid item xs={12} sm={5} md={4} lg={2}>
                <label>From Date</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker slotProps={{ textField: { name: 'fromDate' } }} sx={{ width: "100%" }} />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={5} md={4} lg={2}>
                <label>To Date</label>
                <LocalizationProvider dateAdapter={AdapterDayjs} >
                  <DatePicker slotProps={{ textField: { name: 'toDate' } }} sx={{ width: "100%" }} />
                </LocalizationProvider>
              </Grid>
            </Grid>
            <Grid container rowGap={2} columnGap={2} mt={2}>
              <Grid item xs={12} sm={5} md={4} lg={2} alignItems='flex-end' display='flex'>
                <Button variant="contained" type="submit" fullWidth={true} size="large" color="primary">Filter Order</Button>
              </Grid>
              <Grid item xs={12} sm={5} md={4} lg={2} alignItems='flex-end' display='flex'>
                <Button variant="contained" type="button"
                   onClick={ClearFilter} 
                  fullWidth={true} size="large" color="secondary">Clear Filter</Button>
              </Grid>
            </Grid>
          </Typography>
        </Box>
        <StickyHeadTable
          rowData={tableData} columnData={columns}
        />
        <Grid container mt={2}>
          <Grid item xs={12} sm={5} md={4} lg={2} alignItems='flex-end' display='flex'>
            <Button variant="contained" type="button" onClick={() => { ExportData(tableData, "shandoor", "shandoorReport.xlsx") }} fullWidth={true} size="large" color="success">Export To Excel</Button>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default ShandoorTab