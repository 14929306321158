const productList = []

const handleProduct = (state = productList, action) => {
    const product = action.payload
    switch (action.type) {
        case "ADDPRODUCTS":
            // Check if product already in cart
            state = product
            return state
        default:
            return state
    }
}

export default handleProduct