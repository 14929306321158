import React, { useEffect } from 'react';
import { useSelector } from "react-redux";

const OrderSummary = (props) => {
    const { subtotal, totalItems, setSubTotal, setTotalItems } = props;
    const state = useSelector((state) => state.handleCart);
    
   // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (state.length > 0) {

            state.map((item) => {
                return ( setSubTotal(subtotal => subtotal += item.price * item.qty));
            });

            state.map((item) => {
                return (setTotalItems(totalItems => totalItems += (item.title === "Cow Qurbani") ? item.qty * 7 : item.qty));
            });
        }
    }, [])
    return (
        <div className="card mb-4">
            <div className="card-header py-3 bg-light" style={{borderRadius : '16px'}}>
                <h5 className="mb-0 black-font">Order Summary</h5>
            </div>
            <div className="card-body">
                <ul className="list-group list-group-flush">
                    <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 pb-0 black-font">
                        Products ({totalItems})<span>${Math.round(subtotal)}</span>
                    </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 mb-3 black-font">
                        <div>
                            <strong>Total Amount</strong>
                        </div>
                        <span>
                            <strong>${Math.round(subtotal)}</strong>
                        </span>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default OrderSummary