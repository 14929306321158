import React from 'react';
import { Base_Url } from '../context';

const CartImages = (props) => {
    return (
        <>
            <img
                className=" card-img-top p-5 pt-3 pb-3"
                src={`${Base_Url}get/en_${props.product.image}`}
                alt="Card"
                style={{ maxWidth: '300px', maxHeight: '300px', width: '100%', height: '100%' }}
            />
        </>
    )
}

export default CartImages;