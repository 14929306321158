import { Box } from "@mui/material";
import React, {memo} from "react";
import CartImages from "./CartImages";
import YellowStar from '../assets/yellow_star.webp';
import GreyStar from '../assets/grey_star.webp';

const CartTop = memo((props) => {
    return (
      <>
        <div className="text-center">
          <CartImages product={props.product} />
        </div>
        <div className="card-body pl-4 pt-0">
          <Box component={'h1'} sx={{ fontSize: { xs: '22px', md: '25px' }, fontWeight: 500 }} className="card-title text-start">
            {props.product.title}
          </Box>
          <Box component={'p'} sx={{ fontSize: '15px', fontWeight: 400, color: '#727272' }} className="card-title text-start">
            {props.product.description.substring(0, 55)}...
          </Box>
          <Box component={'img'} src={YellowStar} alt="rating_star" width={'auto'}></Box>
          <Box component={'img'} src={YellowStar} alt="rating_star" width={'auto'}></Box>
          <Box component={'img'} src={YellowStar} alt="rating_star" width={'auto'}></Box>
          <Box component={'img'} src={GreyStar} alt="rating_star" width={'auto'}></Box>
          <Box component={'img'} src={GreyStar} alt="rating_star" width={'auto'}></Box>
        </div>
      </>
    )
  });

  export default CartTop