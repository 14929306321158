import { Typography, Grid, Button } from "@mui/material";
import StickyHeadTable from "./Table";
import { useEffect, useState } from "react";
import axios from "axios";
import { Base_Url } from "../context";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import UserDetailDialog from "./UserDetailDialog";
import { ExportToExcel } from "./ExportExcel";

function UserTab(props) {
  const { children, value, index, ...other } = props;
  const [columns] = useState(['Id', 'Name', 'Uid', 'Address1', 'Country', 'State', 'City']);
  const [tableData, setTableData] = useState();
  const [userDetailDialog, setUserDetailDialog] = useState(false);
  const [userOrders, setUserOrder] = useState([]);

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = async () => {
    const userList = axios.get(`${Base_Url}user/list`);
    setTableData((await userList).data);
  }

  const ShowDetails = async (rowItem) => {
    const res = await axios.post(`${Base_Url}order/userId/${rowItem.Id}`);
    setUserOrder({ 'orders': res.data, 'user': rowItem });
    setUserDetailDialog(true)
  }

  const handleOnClick = (action, data) => {
    const value = Object.keys(action);
    action[value[0]](data)
  }

  return (
    <>
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        <Typography variant="h3" sx={{ fontWeight: 600, fontSize: { xs: '28px', sm: '35px' } }} className="text-start mt-5" >User Management</Typography>
        <StickyHeadTable
          rowData={tableData} columnData={columns}
          handleOnClick={handleOnClick}
          ActionArray={[{ ICON: <InfoOutlinedIcon />, FUNCTION: { ShowDetails } }]}
        />
        <Grid container mt={2}>
          <Grid item xs={12} sm={5} md={4} lg={2} alignItems='flex-end' display='flex'>
            <Button variant="contained" type="button" onClick={() => { ExportToExcel(tableData, "user", "userList.xlsx") }} fullWidth={true} size="large" color="success">Export To Excel</Button>
          </Grid>
        </Grid>
        <UserDetailDialog open={userDetailDialog} setOpen={setUserDetailDialog} userOrder={userOrders} />
      </div>
    </>
  )
}

export default UserTab