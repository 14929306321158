import React from 'react'
import { Footer, Navbar, Product } from "../components"
import { Box } from '@mui/material'

const Products = () => {
  return (
    <>
      <Box variant={'div'} className="full-vp">
        <Navbar />
        <div className="container my-3 py-3">
          <h1 className="text-center">Products</h1>
          <hr />
        </div>
        <Box variant={'div'} className="flex-1">
          <Product />
        </Box>
        <Footer />
      </Box>
    </>
  )
}

export default Products