import { Typography, Button, MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import SnackBar from "./SnackBar";
import { useEffect, useState } from "react";
import axios from "axios";
import { Base_Url } from "../context";
import StickyHeadTable from "./Table";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ImageModal from "./ImageModal";
import Compressor from 'compressorjs';


function ProductTab(props) {
  const { children, value, index, ...other } = props;
  const [columns] = useState([ 'Id', 'title', 'description', 'price', 'image']);
  const [tableData, setTableData] = useState();
  const [snackBar, setSnackBar] = useState(false);
  const [dialogImage, setDialogImage] = useState('');
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [product, setProduct] = useState({
    productName: '',
    productPrice: '',
    productDescription: '',
    productImage: null,
    productImageName : '',
    productCategory : 'accessories'
  });
// eslint-disable-next-line
  useEffect(() => {
    GetAllProduct()
  },[]);

  const CompressMasjidProfileImage = (image, width, height) => {
    return new Promise(promise => {
      new Compressor(image, {
        quality: 0.8,
        maxWidth: width,
        maxHeight: height,
        success: (compressedResult) => {
          promise(compressedResult);
        }
      });
    })
  }

  const AddProduct = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('productName', product.productName);
    formData.append('productPrice', product.productPrice);
    formData.append('productDescription', product.productDescription);
    formData.append('productCategory', product.productCategory);
    // formData.append('productImage', product.productImage);
    formData.append('productImageName', product.productImageName);

    const image = product.productImage;
    let width;
    let height;
    for (var x = 0; x < 3; x++) {
      if(x === 1){
        width = 300;
        height = 300;
      }
      if(x === 2){
        width = 50;
        height = 50;
      }
      let compressImage = await CompressMasjidProfileImage(image, width, height );
      if(x === 0){
        formData.append('productImage', compressImage, product.productImageName );
      }
      if(x === 1){
        formData.append('productImage', compressImage, 'en_'+ product.productImageName );
      }
      if(x === 2){
        formData.append('productImage', compressImage, 'tbn_'+ product.productImageName );
        const response = await axios.post(`${Base_Url}product/add`, formData);
        if (response.status === 200) {
          setSnackBar(true);
        }
      }
    }
  }

  const OpenImageDialog = (rowItem) => {
    setDialogImage({
      image : Base_Url+"get/"+rowItem.imageName,
      title : rowItem.title
    });
    setOpenImageDialog(true);
  }

  const GetAllProduct = async () => {
    const productList = await axios.get(`${Base_Url}product/all`);
    var tempData = productList.data;
    tempData.forEach((element, index) => {
      element['imageName'] = element.image;
      element['image'] = <img src={Base_Url+'get/tbn_'+element.image} alt={element.image+"-"+index} width={50} onClick={() => {OpenImageDialog(element)}} style={{cursor : 'pointer'}} />;
    });
    setTableData(tempData);
  }

  const handleImageChange = (e) => {
    setProduct({
      ...product,
      [e.target.name]: e.target.files[0],
      productImageName: e.target.files[0].name
    });
  };

  const handleInputChange = (e) => {
    setProduct({
      ...product,
      [e.target.name]: e.target.value,
    });
  };

  const handleOnClick = (action, data) => {
    const value = Object.keys(action);
    action[value[0]](data)
  }

  const deleteProduct = async (rowItem) => {
    console.log(rowItem)
    const body = {
      Id : rowItem.Id
    }
    const response = await axios.post(`${Base_Url}product/delete`, body);
    if(response.status === 200){
      var tempArr = tableData.filter(product => product.Id !== rowItem.Id);
      setTableData(tempArr);
    }
    console.log(response.data)
  }

  // const UpdateProduct = async (rowItem) => {
  //   console.log(rowItem)
  //   const body = {
  //     Id : rowItem.Id
  //   }
  //   const response = await axios.post(`${Base_Url}product/update`, body);
  //   if(response.status === 200){
  //     var tempArr = tableData.filter(product => product.Id !== rowItem.Id);
  //     setTableData(tempArr);
  //   }
  //   console.log(response.data)
  // }
  return (
    <>
    <ImageModal open={openImageDialog} setOpen={setOpenImageDialog} dialogData={dialogImage} />
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >

        <Typography variant="h3" sx={{ fontWeight: 'bolder', fontSize: { xs: '28px', sm: '35px' } }} className="text-start mt-5" >Create Product</Typography>

        <Box
          component="form" onSubmit={AddProduct}
          sx={{
            '& .MuiTextField-root': { m: 1, mt: 3, width: '40ch' },
          }}
          noValidate
          autoComplete="off"
        >

          <TextField
            id="outlined-helperText"
            label="Product Name" value={product.productName} onChange={handleInputChange}
            name="productName"
            color="secondary" />

          <TextField
            id="outlined-helperText" type="file" onChange={handleImageChange}
            name="productImage"
            color="secondary" />

          <TextField
            id="outlined-helperText"
            label="Product Price" value={product.productPrice} onChange={handleInputChange}
            name="productPrice"
            color="secondary" />

          <FormControl variant="outlined" sx={{margin : '8px', marginTop : '24px', width : '200px'}} required>
            <InputLabel id="outlined-helperText">Product Category</InputLabel>
            <Select value={product.productCategory} name="productCategory"
              labelId="product-category" label="Product Category" 
              onChange={handleInputChange}
              id="category">
              <MenuItem value=''>
                <em>Select Version</em>
              </MenuItem>
              <MenuItem value="accessories">Other</MenuItem>
              <MenuItem value="qurbani">Qurbani</MenuItem>
            </Select>
          </FormControl>

          <Typography component="div">

            <TextField
              id="outlined-helperText"
              label="Product Description"
              name="productDescription"
              value={product.productDescription} onChange={handleInputChange}
              multiline
              rows={4}
              color="secondary" />

          </Typography>


          <Button variant="contained" color="primary" type="submit" sx={{marginTop : '24px', m : 1}} >Add Product</Button>
        </Box>
        <SnackBar message={"Product Added Successfully. !"} messageType={"success"} open={snackBar} setOpen={setSnackBar} />
        <StickyHeadTable rowData={tableData} columnData={columns} handleOnClick={handleOnClick}
          ActionArray={[ { ICON: <DeleteOutlineIcon titleAccess="Delete Product" />, FUNCTION: { deleteProduct } }]} />
      </div>
    </>
  )
}

export default ProductTab