import React from 'react'
import { NavLink } from 'react-router-dom'
// import Marquee from "react-fast-marquee";
import { useSelector } from 'react-redux'
import { Button } from '@mui/material';
import logo from '../assets/logo.webp'
import NavbarCartButton from './NavbarCartButton';

const Navbar = () => {
    console.log("navbar")
    return (
        <>
            {/* <Marquee direction='right' play={false} pauseOnHover={true} style={{ backgroundColor: 'black', color: 'white', paddingTop: '5px' }}>
                {
                    (configuration && configuration.IsQurbani && configuration.IsQurbani === "ON") ?
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ wordSpacing: '0px', letterSpacing: '0px', fontSize: '15px', fontFamily: 'Roboto', marginLeft: '20px', marginRight: '10px' }}> [Sura Al Hajj - (aya - 37) ] </span>
                            <h5 style={{ paddingTop: "10px", fontFamily: 'PDMS_Saleem_QuranFont', fontSize: '30px', wordSpacing: '3px', letterSpacing: '1px' }}>  لَن يَنَالَ ٱللَّهَ لُحُومُهَا وَلَا دِمَآؤُهَا وَلَـٰكِن يَنَالُهُ ٱلتَّقۡوَىٰ مِنكُمۡ‌ۚ كَذَٲلِكَ سَخَّرَهَا لَكُمۡ لِتُكَبِّرُواْ ٱللَّهَ عَلَىٰ مَا هَدَٮٰكُمۡ‌ۗ وَبَشِّرِ ٱلۡمُحۡسِنِينَ</h5>
                        </div>
                        :
                        <h5 style={{ paddingTop: "10px", fontFamily: 'Roboto', fontSize: '18px', wordSpacing: '3px', letterSpacing: '1px' }}>Welcome To Pak Store</h5>
                }
            </Marquee> */}
            <nav className="navbar navbar-expand-lg navbar-light bg-light sticky-top box-shadow">
                <div className="container">
                    <NavLink className="navbar-brand fw-bold fs-4 px-2" to="/"> <img src={logo} width="150px" alt="QM Logo" style={{ backgroundColor: '#f8f9fa' }} /></NavLink>
                    <button className="navbar-toggler mx-2" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav m-auto my-2 text-center">
                            <li className="nav-item">
                                <NavLink className="nav-link ml-3 mr-3" to="/">Home </NavLink>
                            </li>
                            <li className="nav-item mr-3 ml-3">
                                <NavLink className="nav-link" to="/product">Products</NavLink>
                            </li>
                            <li className="nav-item mr-3 ml-3">
                                <NavLink className="nav-link" to="/contact">Contact</NavLink>
                            </li>
                            <li className="nav-item ml-3 mr-3">
                                <NavLink className="nav-link" to="/about">About</NavLink>
                            </li>
                            <li className="nav-item ml-3">
                                <NavLink className="nav-link" to="/admin">AdminDashboard</NavLink>
                            </li>
                        </ul>
                        <div className="buttons text-center">
                        <NavbarCartButton />
                        </div>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Navbar