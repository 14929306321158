import { Typography } from "@mui/material";

function ContentTab(props) {
  const { children, value, index, ...other } = props;
  return (
    <>
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        <Typography variant="h4" className="text-center mt-5" >Content Tab</Typography>
      </div>
    </>
  )
}

export default ContentTab