import { Typography } from '@mui/material'
import React from 'react'

const HelloPage = () => {
  return (
    <Typography variant='div' component='h1' sx={{justifyContent : 'center' , display : 'flex', alignItems : 'center', height : '100vh'}}>
        Hello World
    </Typography>
  )
}

export default HelloPage