import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { Base_Url } from "../context";
import { Button, Typography } from "@mui/material";
import BillingInformation from "./BillingInformation";
import QurbaniInformation from "./QurbaniInformation";
import PaymentInformation from "./PaymentInformation";
import OrderSummary from "./OrderSummary";
import SnackBar from './SnackBar';
import { countryJSON } from "./countryJson.js";
import BackDrop from "./Backdrop";
import { showBackDrop, hideBackDrop, showSnackBar } from "../redux/action";



const ShowCheckout = () => {
    const state = useSelector((state) => state.handleCart);
    const configuration = useSelector((configuration) => configuration.handleConfiguration);
    const dispatch = useDispatch();
    const [productId, setProductId] = useState("");
    const [goatId, setGoatId] = useState("0G");
    const [sheepId, setSheepId] = useState("0S");
    const [partId, setPartId] = useState("0P");
    const [cowId, setCowId] = useState("0C");
    const [country, setCountry] = useState('');
    const [region, setRegion] = useState('');
    const [phone, setPhone] = useState("");
    const [formState, setFormState] = useState(1);
    const [subtotal, setSubTotal] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [hblObj, setHblObj] = useState();
    const [totalFromState, setTotalFormState] = useState(3)
    const [gatewayVersion, setGatewayVersion] = useState();
    var productDetail = [];
    var feildsNotForHBL = ['Cow Qurbani', 'Goat Qurbani', 'Sheep Qurbani', 'Cow Part Qurbani', 'IndividualParts', 'rcrs-country', 'rcrs-region', 'year', 'month', 'btnsubmit'];
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({
        bill_to_forename: '',
        bill_to_surname: '',
        bill_to_email: '',
        bill_to_address_line1: '',
        bill_to_address_city: '',
        bill_to_address_postal_code: '',
        bill_to_address_country: '',
        bill_to_address_state: '',
        bill_to_phone: '',
    });
    const [cardDetails, setCardDetails] = useState({
        card_number: "",
        year: new Date().getFullYear(),
        month: new Date().getUTCMonth() < 10 ? "0" + new Date().getMonth() : new Date().getMonth(),
        card_account_type: "CR",
        card_type: "001",
        card_type_selection_indicator: "1",
        card_cvn: "",
    });



// eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        setGatewayVersion(configuration.GatewayVersion);
        if (configuration.GatewayVersion === "NV") {
            if (state[0].category === "qurbani") {
                setTotalFormState(3);
            }
            else {
                setTotalFormState(2);
            }
        }
        if (configuration.GatewayVersion === "OV") {
            if (state[0].category === "qurbani") {
                setTotalFormState(2);
            }
            else {
                setTotalFormState(1);
            }
        }
    }, []);

    useEffect(() => {
        if (state && state.length) {
            state.forEach((cartItem) => {
                if (cartItem.category === "qurbani") {

                    if (cartItem.title === "Cow Qurbani") {
                        setCowId(cartItem.qty + "C");
                    }
                    if (cartItem.title === "Cow Part Qurbani") {
                        setPartId(cartItem.qty + "P");
                    }
                    if (cartItem.title === "Goat Qurbani") {
                        setGoatId(cartItem.qty + "G");
                    }
                    if (cartItem.title === "Sheep Qurbani") {
                        setSheepId(cartItem.qty + "S");
                    }
                    setProductId("Qurbani_" + goatId + sheepId + partId + cowId);
                }
                else {
                    setProductId("Accessories");
                }
            })
        }
    }, [productId])

    const getSignature = async (requestBody) => {
        const body = requestBody;
        const res = await axios.post(`${Base_Url}signature/create`, body);
        let dataObj = res.data;
        setHblObj(dataObj);
        var objectArray = Object.keys(dataObj).map(key => ({
            key: key,
            value: dataObj[key]
        }));
        return objectArray;
    };

    const createUser = async (requestBody) => {
        const body = requestBody;
        const res = await axios.post(`${Base_Url}order/create`, body);
        return res
    };



    const ContinueToCheckout = async (event) => {
        event.preventDefault();
        dispatch(showBackDrop());
        if (state.length > 0) {
            var totalAmount = 0;
            state.forEach(item => {
                totalAmount = totalAmount + item.qty * item.price;
            })
        }
        GetProductDetails();
        var paymentPayload = {};
        var userEmail = document.getElementById("bill_to_email").value ? document.getElementById("bill_to_email").value : "";
        userEmail = userEmail.replace("@", "___");
        for (let i = 0; i < event.target.length; i++) {
            if (event.target[i].name !== "" && event.target[i].name !== null && event.target[i].name !== undefined) {
                if (!feildsNotForHBL.includes(event.target[i].name))
                    paymentPayload[event.target[i].name] = event.target[i].value;
            }
        }
        paymentPayload['amount'] = totalAmount;
        paymentPayload['consumer_id'] = userEmail;
        paymentPayload['bill_to_phone'] = phone;
        paymentPayload['bill_to_address_country'] = country;
        paymentPayload['bill_to_address_state'] = region;
        paymentPayload['countryName1'] = countryJSON[country];
        if (gatewayVersion === "NV") {
            paymentPayload['card_expiry_date'] = cardDetails.month + "-" + cardDetails.year;
        }
        await getSignature(paymentPayload);
        paymentPayload['IndividualParts'] = totalItems;
        paymentPayload['orderproduct'] = productDetail;
        console.log(paymentPayload)

        var userData = await createUser(paymentPayload);
        if (userData.status === 200) {
            const messageDetail = {
                state: true,
                message: "Your Order has been placed. Please wait for OTP",
                messageType: "success"
            }
            dispatch(showSnackBar(messageDetail));
            const hblForm = document.getElementById("hbl-form");
            if (gatewayVersion === "NV") {
                hblForm.action = "https://testsecureacceptance.cybersource.com/silent/pay";
            }
            else {
                hblForm.action = "https://secureacceptance.cybersource.com/pay"
            }
            setTimeout(() => {
                hblForm.submit();
                dispatch(hideBackDrop());
            }, [2000])
        }
    }

    const GetProductDetails = () => {
        productDetail = [];
        if (state.length > 0) {
            state.forEach((item) => {
                if (item.title !== "Cow Qurbani") {
                    var names = ""
                    for (let index = 1; index <= item.qty; index++) {
                        const element = document.getElementById(`${item.title}-${index}`) && document.getElementById(`${item.title}-${index}`).value != "" ? document.getElementById(`${item.title}-${index}`).value : " ";
                        if(item.qty === 1){
                            names += element;
                        }
                        if(item.qty > 1 && index != item.qty){
                            names += element+"|*|"
                        }
                        if(index === item.qty){
                            if(item.qty != 1){
                                names += element
                            }
                            const productName = item.title.replace(" Qurbani", "");
                            productDetail.push({ productId: item.Id, Name: names, Amount: item.price, ProductName: productName, Qty : item.qty });
                        }
                    }
                }
                else {
                    var fullCow = document.getElementsByName("Cow Qurbani");
                    var elementValue = "";
                    fullCow.forEach((feild, index) => {
                        if ((index + 1) % 7 !== 0) {
                            elementValue = feild.value && feild.value !== "" ? `${elementValue}${feild.value}|*|` : `${elementValue} |*|`;
                        }
                        if (index !== 0 && index !== 1 && (index + 1) % 7 === 0) {
                            if(item.qty === 1){
                                elementValue = feild.value && feild.value !== "" ? `${elementValue}${feild.value}` : `${elementValue} `;
                            }
                            if(item.qty > 1 && index != (item.qty * 7)-1){
                                elementValue = feild.value && feild.value !== "" ? `${elementValue}${feild.value}|*|` : `${elementValue} |*|`;
                            }
                            // elementValue = elementValue.trim();
                        }
                            if(index === (item.qty * 7)-1){
                                if(item.qty != 1){
                                    elementValue = feild.value && feild.value !== "" ? `${elementValue}${feild.value}` : `${elementValue} `;
                                }
                                const productName = item.title.replace(" Qurbani", "");
                                productDetail.push({ productId: item.Id, Name: elementValue, Amount: item.price, ProductName: productName, Qty : item.qty });
                            }
                            // elementValue = "";
                    })
                }
            }
            )
        }
    }

    const ValidateAndNext = () => {

        const newErrors = {};
        if (formState === 1) {
            if (formData.bill_to_forename.trim() === '') {
                newErrors.bill_to_forename = 'First Name is required';
            }
            else if (formData.bill_to_surname.trim() === '') {
                newErrors.bill_to_surname = 'Last Name is required';
            }
            else if (formData.bill_to_email.trim() === '') {
                newErrors.bill_to_email = 'Email is required';
            }
            else if (formData.bill_to_address_line1.trim() === '') {
                newErrors.bill_to_address_line1 = 'Address is required';
            }
            else if (formData.bill_to_address_city.trim() === '') {
                newErrors.bill_to_address_city = 'City is required';
            }
            else if (formData.bill_to_address_postal_code.trim() === '') {
                newErrors.bill_to_address_postal_code = 'Postal code is required';
            }
            else if (phone.trim() === '') {
                const messageDetail = {
                    state: true,
                    message: "Phone Number is required. !",
                    messageType: "error"
                }
                dispatch(showSnackBar(messageDetail));
                newErrors.bill_to_phone = "Phone number is required";
            }
            else if (country.trim() === '') {
                const messageDetail = {
                    state: true,
                    message: "Country is required. !",
                    messageType: "error"
                }
                dispatch(showSnackBar(messageDetail));
                newErrors.bill_to_address_country = "Country name is required";
            }
            if (formData.bill_to_address_postal_code.trim() !== '') {
                var regix = "";
                if (country === "US") {
                    regix = /^\d{5}-\d{4}$/;
                }
                if (country === "CA") {
                    regix = /^[A-Za-z]\d{1}[A-Za-z] \d{1}[A-Za-z]\d{1}$/;
                }
                if (regix !== "") {
                    const isValidFormat = regix.test(formData.bill_to_address_postal_code);
                    if (!isValidFormat) {
                        newErrors.bill_to_address_postal_code = 'Postal Code is invalid';
                        const messageDetail = {
                            state: true,
                            message: "Postal code is invalid. !",
                            messageType: "error"
                        }
                        dispatch(showSnackBar(messageDetail));
                    }
                }
            }
            if (formData.bill_to_email.trim() !== '') {
                var emailRegix = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
                const isValidFormat = emailRegix.test(formData.bill_to_email);
                if (!isValidFormat) {
                    const messageDetail = {
                        state: true,
                        message: "Email Address is invalid !",
                        messageType: "error"
                    }
                    dispatch(showSnackBar(messageDetail));
                }
            }
            if ((country === "US" || country === "CA") && (region === "" || region === null || region === undefined)) {
                const messageDetail = {
                    state: true,
                    message: `Region is required for ${countryJSON[country]}`,
                    messageType: "error"
                }
                dispatch(showSnackBar(messageDetail));
            }
        }

        if (Object.keys(newErrors).length === 0) {
            setErrors({});
            setFormState(formState + 1);
        } else {
            setErrors(newErrors);
        }
    }

    return (
        <>
            <BackDrop />
            <SnackBar />
            <div className="container py-5">
                <div className="row my-4">
                    <div className="col-md-12 col-lg-4 order-lg-last">
                        <OrderSummary totalItems={totalItems} subtotal={subtotal} setSubTotal={setSubTotal} setTotalItems={setTotalItems} />
                    </div>
                    <div className="col-md-12 col-lg-8">
                        <form className="needs-validation" id="checkout-form" noValidate={true} onSubmit={ContinueToCheckout} method="POST" >
                            <div className="card mb-4">
                                <Typography component="div" display="flex" justifyContent="space-between" className="card-header py-3" sx={{ backgroundColor: "#009591", color: "white", borderTopLeftRadius : "16px !important", borderTopRightRadius : "16px !important" }} >
                                    {(formState === 1) ?
                                        <h4 className="mb-0">Billing Information</h4> : null
                                    }
                                    {(formState === 2 && state[0].category === "qurbani") ?
                                        <h4 className="mb-0">Qurbani Information</h4> : null
                                    }
                                    {((formState === 3 && gatewayVersion && gatewayVersion !== undefined && gatewayVersion !== null && gatewayVersion === "NV" )|| (formState === 2 && state[0].category !== "qurbani")) ?
                                        <h4 className="mb-0">Payment Details</h4> : null
                                    }
                                    <Typography color="white" justifyContent="flex-end">Step {formState} of {totalFromState}</Typography>
                                </Typography>

                                <div className="card-body" style={{ display: formState === 1 ? "initial" : "none" }}>
                                    <BillingInformation errors={errors} setErrors={setErrors} formData={formData} setFormData={setFormData} gatewayVersion={gatewayVersion} productId={productId} country={country} region={region} setCountry={setCountry} setRegion={setRegion} phone={phone} setPhone={setPhone} />
                                </div>

                                {/* {(formState === 2 && state[0].category === "qurbani") ? */}
                                    <div className="card-body" style={{ display: formState === 2 && state[0].category === "qurbani" ? "block" : "none" }}>
                                        <QurbaniInformation />
                                    </div>
                                    {/* : null
                                } */}

                                {(gatewayVersion && gatewayVersion !== undefined && gatewayVersion !== null && gatewayVersion === "NV") ?
                                    <div className="card-body" style={{ display: formState === totalFromState ? "initial" : "none" }}>
                                        <PaymentInformation cardDetails={cardDetails} setCardDetails={setCardDetails} />
                                    </div>
                                    : null
                                }
                                <Typography component="div" sx={{ p: 2, display: formState === totalFromState ? "block" : "none", textAlign  : 'center' }} >
                                    <Button id="checkout-button" variant="contained" type="submit" fullWidth={true} sx={{ bgcolor: '#000000', ":hover": { bgcolor: "#EA6724" }, borderRadius: '5px' }} size="large" >Continue To Checkout</Button>
                                    <Typography component={'div'} sx={{ color: "#000000", fontFamily : "Roboto" }} mt={1} >
                                    <span style={{color : "red", fontWeight : 700}}> Note :</span> As this is a WAQF Qurbani (Udhiya), the meat will not be sent to your address. It will be distributed to needy families only.
                                    </Typography>
                                    <Typography component={'div'} sx={{ color: "#000000", fontFamily : "PDMS_Saleem_QuranFont", letterSpacing : '1px', fontSize : '24px' }} mt={1} >
                                    <span style={{color:"red"}} >ملحوظة: </span> بما أن هذه الأضحية وقف، فلا يرسل اللحم إلى عنوانك. سيتم توزيعه على العائلات المحتاجة فقط
                                    </Typography>
                                </Typography>
                                <Typography component="div" sx={{ p: 2, justifyContent: 'space-between', display: 'flex' }}>
                                    <Button variant="contained" sx={{ bgcolor: '#000000', ":hover": { bgcolor: "#EA6724" }, borderRadius: '5px' }} type="button" fullWidth={false} size="large" onClick={(() => { setFormState(formState - 1) })} disabled={formState === 1 ? true : false} >Prev</Button>
                                    <Button variant="contained" sx={{ bgcolor: '#000000', ":hover": { bgcolor: "#EA6724" }, borderRadius: '5px' }} type="button" fullWidth={false} size="large" onClick={ValidateAndNext} disabled={formState === totalFromState ? true : false} >Next</Button>
                                </Typography>

                            </div>
                        </form>
                        <form hidden id="hbl-form" method="POST">
                            {(hblObj !== null && hblObj !== undefined) ?
                                Object.keys(hblObj).map((fieldName, index) => {
                                    return (
                                        <input name={fieldName} value={hblObj[fieldName] !== null && hblObj[fieldName] !== undefined && hblObj[fieldName] !== "" ? hblObj[fieldName] : ""} readOnly hidden key={index} />
                                    )
                                })
                                : null
                            }
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ShowCheckout;



// // : 
// 
// access_key,
// profile_id,
// transaction_uuid,
// signed_field_names,
// unsigned_field_names,
// signed_date_time,
// locale,
// transaction_type,
// reference_number,
// amount,
// currency,
// bill_to_address_city,
// bill_to_address_country,
// bill_to_address_line1,
// bill_to_address_line2,
// bill_to_address_postal_code,
// bill_to_address_state,
// bill_to_forename,
// bill_to_phone,
// bill_to_surname,
// bill_to_email,
// consumer_id,
// customer_ip_address,
// item_0_sku,
// item_0_code,
// item_0_name,
// item_0_quantity,
// merchant_defined_data1,
// merchant_defined_data2,
// merchant_defined_data3,
// merchant_defined_data4,
// merchant_defined_data5,
// merchant_defined_data7,
// merchant_defined_data20,
// merchant_defined_data31






































