import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { insertProducts } from "../redux/action";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import axios from "axios";
import { Base_Url } from "../context";
import { Box, Container, Grid } from "@mui/material";
import CartBottom from "./ProductsCartBottom";
import CartTop from "./ProductsCartTop";

const Products = () => {

  const [loading, setLoading] = useState(true);
  const productList = useSelector((state) => state.handleProduct);
  const dispatch = useDispatch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const getProducts = async () => {
      setLoading(true);
      const response = await axios.post(`${Base_Url}product/list`);
      if (response) {
        dispatch(insertProducts(response.data))
        setLoading(false);
      }
    };
    if (productList === null || productList === undefined || productList.length < 1) {
      getProducts();
    }
    else{
      setLoading(false)
    }
  }, []);

  const ShowProducts = () => {
    return (
      <>
        <Container>
          <Grid container columnSpacing={2} rowGap={3} justifyContent={'center'}>
            {productList.map((product, index) => {
              return (
                <Grid item xs={10} sm={5} md={4} lg={3} key={index} justifyContent={'center'} display={'flex'} width={'100%'} >
                  <Box component={'div'} id={product.Id} maxWidth={'100%'} >
                    <div className="card parent-card h-100 card-hr" key={product.Id}  >
                      <CartTop product={product} />
                      <hr style={{ margin: 0 }} />
                      <CartBottom product={product} />
                    </div >
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Container>
      </>
    );
  };
  return (
    <>
      <div className="my-3 py-3">
        {loading ? <ProductCardSkeleton /> : <ShowProducts />}
      </div>
    </>
  );
};

export default Products;

const ProductCardSkeleton = () => {
  return (
    <>
      <Container>
        <Grid container columnSpacing={2} rowGap={3} justifyContent={'center'}>
          <Grid item xs={10} sm={5} md={4} lg={3} width={'100%'} >
            <Skeleton height={400} />
          </Grid>
          <Grid item xs={10} sm={5} md={4} lg={3} width={'100%'} >
            <Skeleton height={400} />
          </Grid>
          <Grid item xs={10} sm={5} md={4} lg={3} width={'100%'} >
            <Skeleton height={400} />
          </Grid>
          <Grid item xs={10} sm={5} md={4} lg={3} width={'100%'} >
            <Skeleton height={400} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};