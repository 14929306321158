const showSnackBar = {
    state: false,
    message: "",
    messageType: "success"
};

export const handleSnackBar = (state = showSnackBar, action) => {
    if (action.payload) {
        const messageData = action.payload
        switch (action.type) {
            case "ShowSnackBar":
                return messageData;
            case "HideSnackBar":
                return messageData;
            default:
                return state
        }
    }
    else {
        return state
    }
}