import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function BasicAccordion() {
    var faqsArr = [
        {ques : "Why was this service started?", ans : "We began this service when many Muslims were unable to do Udhiyah/Qurbani owing to the worldwide pandemic of Covid 19. We believed that it was our responsibility as the most trusted Islamic application to assist our users in carrying out their religious requirements in a safe & healthy manner." },
        {ques : "Why should I choose Waqf Qurbani/Udhiyah?", ans : "When you choose PakData's Waqf Qurbani/Udhiyah service, you will be helping people who cannot afford to celebrate Eid-ul-Adha. Meat from Qurbani will be supplied to the poor throughout the country, and all revenues from the sale of animal hides will be used to help those in need." },
        {ques : "What is the selection process for Qurbani animals?", ans : "We have collaborated with well-known institutions to deliver healthy goats, sheep, and cows while guaranteeing that slaughtering services are sanitary and following Islamic injunctions." },
        {ques : "Will I get the Qurbani meat?", ans : "We do not offer meat to donors as the entire Qurbani meat is distributed amongst the deserving & underprivileged families in different regions of Pakistan." },
        {ques : "Where should I contact if I have any issues while placing a Qurbani/Udhiyah order?", ans : "You can contact us at orders@pakdata.com for any online Qurbani/Udhiyah-related questions, and we will respond as swiftly as possible." },
        {ques : "Where do most of your users reside?", ans : "Our customers come from all over the world, including the United States, the United Kingdom, Canada, Australia, New Zealand, Saudi Arabia, the United Arab Emirates, Europe, India, Pakistan, Japan, China, France, Italy, Russia, Switzerland, Sweden, Norway, Belgium, and Hong Kong." }
    ]
    return (
        <div className="container my-3 py-3">
            <div className="row">
                <div className="col-12">
                {faqsArr.map((faq, index) => {
                    return (
                    
                    <Accordion key={index} sx={{margin : "5px 0px"}} >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography sx={{color : "#5f8a4e", fontSize : "20px"}} >{faq.ques}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                {faq.ans}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                 
                    )}
                )}
                </div>
            </div>
        </div>
    );
}