import * as React from 'react';
import {CircularProgress, Backdrop} from '@mui/material';
import { useSelector } from "react-redux";

export default function BackDrop(props) {
    const state = useSelector((state) => state.handleBackDrop);

    return (

        <Backdrop
            sx={{ color: '#fff', zIndex : 100000 }}
            open={state}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    );
}