import React from "react";
import ReactDOM from "react-dom/client";
import "./App.css";
import App from "./App";
// import reportWebVitals from "./reportWebVitals";
// import { ErrorBoundary } from "./middleware";
// import '../src/fonts/Poppins/Poppins-Regular.ttf'
import './fonts/PDMS_Saleem_ACQuranFont_shipped.ttf';
import './fonts/PDMS_IslamicFont_Android_shipped.ttf';
import './fonts/PDMS_NastaliqNafees_iphone.ttf';
import './fonts/Roboto-Light.ttf';
import { Provider } from 'react-redux';
import store from './redux/store';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        {/* // <ErrorBoundary> */}
        <Provider store={store}>
            <App />
        </Provider>
        {/* // </ErrorBoundary> */}
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
