import React, { memo } from "react";
import { Button } from "@mui/material";
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'

const NavbarCartButton = memo(() => {
    console.log("navbar cart button")
    const state = useSelector(state => state.handleCart);
    return (
        <>
            <NavLink to="/cart" style={{ textDecoration: 'none' }}>
                <Button variant='contained' className="m-2" sx={{ textTransform : 'capitalize', fontSize: { xs: '16px', md: '14px' }, padding : {xs : '6px 30px', md : '6px 16px'}, display : { md : 'initial'}, bgcolor: '#ea6723', borderRadius: '9px', ":hover": { bgcolor: "#000000" } }}>
                    <i className="fa fa-cart-shopping mr-1"></i>Cart ({state.length})
                </Button>
            </NavLink>
        </>
    )
});

export default NavbarCartButton;