import { Grid, Typography } from "@mui/material";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';
import ProductionQuantityLimitsOutlinedIcon from '@mui/icons-material/ProductionQuantityLimitsOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import SellIcon from '@mui/icons-material/Sell';
import { useEffect, useState } from "react";
import axios from "axios";
import { Base_Url } from "../context";

function DashboardBox() {
    
    const [responseData, setResponseData]  = useState();
    useEffect(() => {
        getDashboardDetails();
    },[])

    const getDashboardDetails = async () => {
        const res = await axios.get(`${Base_Url}user/dashboard`);
        setResponseData(res.data);
    }
    return (
        <>
            <Grid container flexWrap='wrap' columnGap={1} justifyContent='flex-start'>

                <Grid item lg={2} sm={8} md={4} xs={12} >
                    <Card sx={{ width: '100%', minHeight : '120px', mt: 1, backgroundColor: 'rgba(15, 39, 115)', color: 'whitesmoke' }}>
                        <CardActionArea>
                            <CardContent>
                                <ProductionQuantityLimitsOutlinedIcon />
                                <br />
                                                                <br />
                                <Typography component='div' display='flex' alignItems="end" justifyContent='space-between'>
                                    <Typography gutterBottom variant="h6" component="div" sx={{fontSize : {sm : '18px', md : '19px', lg : '20px'}}}>
                                        Products
                                    </Typography>
                                    <Typography gutterBottom variant="h6" component="div" sx={{fontSize : {sm : '18px', md : '19px', lg : '20px'}}}>
                                    {responseData && responseData.totalProduct ? responseData.totalProduct : 0}
                                    </Typography>
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>

                <Grid item lg={2} sm={8} md={4} xs={12} >
                    <Card sx={{ width: '100%', minHeight : '120px', mt: 1, backgroundColor: 'rgba(207, 0, 0)', color: 'whitesmoke' }}>
                        <CardActionArea>
                            <CardContent>
                                <SellIcon />
                                <br />
                                                                <br />
                                <Typography component='div' display='flex' alignItems="end" justifyContent='space-between'>
                                    <Typography gutterBottom variant="h6" component="div" sx={{fontSize : {sm : '18px', md : '19px', lg : '20px'}}}>
                                        Total Orders
                                    </Typography>
                                    <Typography gutterBottom variant="h6" component="div" sx={{fontSize : {sm : '18px', md : '19px', lg : '20px'}}}>
                                        {responseData && responseData.totalAmount ? responseData.totalOrder : 0}
                                    </Typography>
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>

                <Grid item lg={2} sm={8} md={4} xs={12} >
                    <Card sx={{ width: '100%', minHeight : '120px', mt: 1, backgroundColor: 'rgba(255, 195, 8)', color: 'whitesmoke' }}>
                        <CardActionArea>
                            <CardContent>
                                <SellIcon />
                                <br />
                                <br />
                                <Typography component='div' display='flex' alignItems="end" justifyContent='space-between'>
                                    <Typography gutterBottom variant="h6" component="div" sx={{fontSize : {sm : '18px', md : '19px', lg : '20px'}}}>
                                        Complete Orders
                                    </Typography>
                                    <Typography gutterBottom variant="h6" component="div" sx={{fontSize : {sm : '18px', md : '19px', lg : '20px'}}}>
                                    {responseData && responseData.completeOrder ? responseData.completeOrder : 0}
                                    </Typography>
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>

                <Grid item lg={2} sm={8} md={4} xs={12} >
                    <Card sx={{ width: '100%', minHeight : '120px', mt: 1, backgroundColor: 'rgb(0 137 255)', color: 'whitesmoke' }}>
                        <CardActionArea>
                            <CardContent>
                                <SellIcon />
                                <br />
                                                                <br />
                                <Typography component='div' display='flex' alignItems="end" justifyContent='space-between'>
                                    <Typography gutterBottom variant="h6" component="div" sx={{fontSize : {sm : '18px', md : '19px', lg : '20px'}}}>
                                        Pending Orders
                                    </Typography>
                                    <Typography gutterBottom variant="h6" component="div" sx={{fontSize : {sm : '18px', md : '19px', lg : '20px'}}}>
                                    {responseData && responseData.pendingOrder ? responseData.pendingOrder : 0}
                                    </Typography>
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>

                <Grid item lg={2} sm={8} md={4} xs={12} >
                    <Card sx={{ width: '100%', minHeight : '120px', mt: 1, backgroundColor: 'rgba(231, 107, 11)', color: 'whitesmoke' }}>
                        <CardActionArea>
                            <CardContent>
                                <SellIcon />
                                <br />
                                                                <br />
                                <Typography component='div' display='flex' alignItems="end" justifyContent='space-between'>
                                    <Typography gutterBottom variant="h6" component="div" sx={{fontSize : {sm : '18px', md : '19px', lg : '20px'}}}>
                                        Total Amount
                                    </Typography>
                                    <Typography gutterBottom variant="h6" component="div" sx={{fontSize : {sm : '18px', md : '19px', lg : '20px'}}}>
                                    {responseData && responseData.totalAmount ? responseData.totalAmount : 0}
                                    </Typography>
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>

                <Grid item lg={2} sm={8} md={4} xs={12} >
                    <Card sx={{ width: '100%', minHeight : '120px', mt: 1, backgroundColor: 'rgba(0, 121, 86)', color: 'whitesmoke' }}>
                        <CardActionArea>
                            <CardContent>
                                <AccountCircleOutlinedIcon />
                                <br />
                                                                <br />
                                <Typography component='div' display='flex' alignItems="end" justifyContent='space-between'>
                                    <Typography gutterBottom variant="h6" component="div" sx={{fontSize : {sm : '18px', md : '19px', lg : '20px'}}}>
                                        Customers
                                    </Typography>
                                    <Typography gutterBottom variant="h6" component="div" sx={{fontSize : {sm : '18px', md : '19px', lg : '20px'}}}>
                                    {responseData && responseData.totalUser ? responseData.totalUser : 0}
                                    </Typography>
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>

                <Grid item lg={2} sm={8} md={4} xs={12} display="none" >
                    <Card sx={{ width: '100%', minHeight : '120px', mt: 1, backgroundColor: 'rgba(207, 0, 0)', color: 'whitesmoke' }}>
                        <CardActionArea>
                            <CardContent>
                                <ProductionQuantityLimitsOutlinedIcon />
                                <br />
                                                                <br />
                                <Typography component='div' display='flex' alignItems="end" justifyContent='space-between'>
                                    <Typography gutterBottom variant="h6" component="div" sx={{fontSize : {sm : '18px', md : '19px', lg : '20px'}}}>
                                        Products
                                    </Typography>
                                    <Typography gutterBottom variant="h6" component="div" sx={{fontSize : {sm : '18px', md : '19px', lg : '20px'}}}>
                                    {responseData && responseData.totalProduct ? responseData.totalProduct : 0}
                                    </Typography>
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>

            </Grid>
        </>
    )
}

export default DashboardBox