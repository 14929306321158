import DashboardBox from "./DashboardBox";

function DashboardTab(props) {
  const { children, value, index, ...other } = props;
  // const data = [
  //   { value: 20, label: 'Cow' },
  //   { value: 10, label: 'Part' },
  //   { value: 15, label: 'Goat' },
  //   { value: 20, label: 'Sheep' },
  // ];

  // const size = {
  //   width: 600,
  //   height: 300,
  // };
  
  return (
    <>
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        <DashboardBox />
        {/* <Box component="div" sx={{ mt: 5, display : {md : 'flex', sm : 'grid'}, justifyContent : {md : 'center', sm : 'center'} }}>
          <Box component="div">
            <Typography variant="h5" component="div" textAlign="center">Products Sold in 2023</Typography>
            <PieChart
              series={[
                {
                  arcLabel: (item) => `${item.label} (${item.value})`,
                  arcLabelMinAngle: 45,
                  data,
                },
              ]}
              sx={{
                [`& .${pieArcLabelClasses.root}`]: {
                  fill: 'white',
                  fontWeight: 'bold',
                  fontSize: '12px'
                },
              }}
              {...size}
            />
          </Box>
          <Box component="div">
            <Typography variant="h5" component="div" textAlign="center">Most Customers</Typography>
            <BarChart
              xAxis={[{ scaleType: 'band', data: ['group A', 'group B', 'group C'] }]}
              series={[{ data: [4, 3, 5] }, { data: [1, 6, 3] }, { data: [2, 5, 6] }]}
              width={500}
              height={300}
            />
          </Box>
        </Box> */}
      </div>
    </>
  )
}

export default DashboardTab