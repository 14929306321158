import React, { useState, useEffect } from 'react'
import { Grid, TextField } from "@mui/material";
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { v4 as uuidv4 } from 'uuid';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import axios from 'axios';
import { useSelector } from "react-redux";


const BillingInformation = (props) => {
    const { productId, country, region, setCountry, setRegion, setPhone, phone, gatewayVersion, formData, setFormData, setErrors, errors } = props;
    const [ip, setIP] = useState("");
    const [date, setDate] = useState("");
    const state = useSelector((state) => state.handleCart);

    useEffect(() => {
        getData();
        setDate(getCurrentDateTimeInUTC());
    }, []);

    const getCurrentDateTimeInUTC = () => {
        const now = new Date();
        const formattedDate = now.toISOString().split('.')[0];  // Remove milliseconds
        return formattedDate + 'Z';
    };

    const getData = async () => {
        const res = await axios.get("https://api.ipify.org/?format=json");
        setIP(res.data.ip);
    };

    const SelectCountryRegion = (event, type) => {
        if (type === 'country') {
            setCountry(event);
            setPhone("")
        }
        else {
            setRegion(event)
        }
    }



    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
        setErrors({
            ...errors,
            [name]: '',
          });
    };
    return (
        <div className="row g-1">
            <Grid container columnSpacing={1} rowSpacing={3}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        label="First Name"
                        id="bill_to_forename"
                         className='w_bg'
                        name="bill_to_forename"
                        inputProps={{maxLength : 60}}
                        fullWidth={true}
                        onChange={handleInputChange}
                        error={!!errors.bill_to_forename}
                        helperText={errors.bill_to_forename}
                        placeholder=""
                        color="success" />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required  className='w_bg'
                        label="Last Name"
                        id="bill_to_surname"
                        name="bill_to_surname"
                        inputProps={{maxLength : 60}}
                        onChange={handleInputChange}
                        fullWidth={true}
                        error={!!errors.bill_to_surname}
                        helperText={!!errors.bill_to_surname}
                        placeholder="Last Name"
                        color="success" />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required  className='w_bg'
                        type="email"
                        label="Email"
                        id="bill_to_email"
                        onChange={handleInputChange}
                        error={!!errors.bill_to_email}
                        helperText={errors.bill_to_email}
                        name="bill_to_email"
                        fullWidth={true}
                        placeholder="you@example.com"
                        color="success" />
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        required  className='w_bg'
                        type="text"
                        label="Address"
                        id="bill_to_address_line1"
                        name="bill_to_address_line1"
                        error={!!errors.bill_to_address_line1}
                        helperText={errors.bill_to_address_line1}
                        onChange={handleInputChange}
                        fullWidth={true}
                        placeholder="123 Main Street"
                        inputProps={{maxLength : 60}}
                        
                        color="success" />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        type="email"  className='w_bg'
                        label="Address 2 (Optional)"
                        id="bill_to_address_line2"
                        name="bill_to_address_line2"
                        fullWidth={true}
                        placeholder="Appartment or Suite"
                        inputProps={{maxLength : 60}}
                        color="success" />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CountryDropdown className="form-select"
                        value={country} defaultOptionLabel="Select Country" valueType="short"
                        onChange={(event) => { SelectCountryRegion(event, 'country') }} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <RegionDropdown className="form-select"
                        value={region} countryValueType="short" valueType="short"
                        country={country} defaultOptionLabel="Select Region" blankOptionLabel="Select Country First"
                        onChange={(event) => { SelectCountryRegion(event, 'region') }} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <PhoneInput containerClass=""
                        country={country.toLowerCase()}
                        enableSearch={true}
                        placeholder="Telephone Number"
                        value={phone}
                        onChange={(phone) => setPhone(phone)}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        type="text"
                        id="bill_to_address_city"
                        name="bill_to_address_city"
                        onChange={handleInputChange}
                         className='w_bg'
                        required
                        fullWidth={true}
                        error={!!errors.bill_to_address_city}
                        helperText={errors.bill_to_address_city}
                        placeholder="Islamabad"
                        label="City"
                        color="success"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        type="text"
                        id="bill_to_address_postal_code"
                        name="bill_to_address_postal_code"
                        onChange={handleInputChange}
                         className='w_bg'
                        required
                        fullWidth={true}
                        error={!!errors.bill_to_address_postal_code}
                        helperText={errors.bill_to_address_postal_code}
                        placeholder="1234"
                        label="Postal Code"
                        color="success"
                    />
                </Grid>
            </Grid>
            <div>
                <input type="text" hidden readOnly id="bill_to_phone" name="bill_to_phone" value="" />
                <input type="text" hidden readOnly id="bill_to_address_country" name="bill_to_address_country" value="" />
                <input type="text" hidden readOnly id="bill_to_address_state" name="bill_to_address_state" value="" />
                <input type="text" hidden readOnly id="amount" name="amount" value="" />
                <input type="text" hidden readOnly id="item_0_sku" name="item_0_sku" value="" />
                <input type="text" hidden readOnly id="item_0_name" name="item_0_name" value="" />
                <input type="text" hidden readOnly id="item_0_quantity" name="item_0_quantity" value="1" />
                <input type="text" hidden readOnly id="merchant_defined_data31" name="merchant_defined_data31" value="" />
                <input type="text" hidden readOnly id="countryName1" name="countryName1" value="" />
                <input type="text" hidden readOnly id="btnsubmit" name="btnsubmit" value="" />
                <input type="text" hidden readOnly id="consumer_id" name="consumer_id" value="" />
                <input type="text" hidden readOnly id="customer_ip_address" name="customer_ip_address" value={ip} />
                <input type="text" hidden readOnly id="transaction_uuid" name="transaction_uuid" value={uuidv4()} />
                <input type="text" hidden readOnly id="signed_date_time" name="signed_date_time" value={date} />
                <input type="text" hidden readOnly id="locale" name="locale" value="en" />
                <input type="text" hidden readOnly id="unsigned_field_names" name="unsigned_field_names" value="" />
                <input type="text" hidden readOnly id="transaction_type" name="transaction_type" value="sale" />
                <input type="text" hidden readOnly id="reference_number" name="reference_number" value="1533273648739" />
                <input type="text" hidden readOnly id="currency" name="currency" value="USD" />
                <input type="text" hidden readOnly id="line_item_count" name="line_item_count" value="01" />
                <input type="text" hidden readOnly id="item_0_code" name="item_0_code" value="electronic_software" />
                <input type="text" hidden readOnly id="merchant_defined_data1" name="merchant_defined_data1" value="WC" />
                <input type="text" hidden readOnly id="merchant_defined_data2" name="merchant_defined_data2" value="Yes" />
                <input type="text" hidden readOnly id="merchant_defined_data3" name="merchant_defined_data3" value="Mobile Application" />
                <input type="text" hidden readOnly id="merchant_defined_data4" name="merchant_defined_data4" value={productId} />
                <input type="text" hidden readOnly id="merchant_defined_data5" name="merchant_defined_data5" value={state[0].category === "qurbani" ? "qurbani_" + uuidv4() : `accessories_${uuidv4()}`} />
                <input type="text" hidden readOnly id="merchant_defined_data6" name="merchant_defined_data6" value="Standard" />
                <input type="text" hidden readOnly id="merchant_defined_data7" name="merchant_defined_data7" value="1" />
                <input type="text" hidden readOnly id="merchant_defined_data8" name="merchant_defined_data8" value="PK" />
                <input type="text" hidden readOnly id="merchant_defined_data20" name="merchant_defined_data20" value="NO" />
                <input type="text" hidden readOnly id="signature" name="signature" value="" />
                {
                    (gatewayVersion === "NV") ?
                        <>
                            <input type="text" hidden readOnly id="signed_field_names" name="signed_field_names" value="access_key,profile_id,transaction_uuid,signed_field_names,unsigned_field_names,signed_date_time,locale,transaction_type,reference_number,amount,currency,bill_to_address_city,bill_to_address_country,bill_to_address_line1,bill_to_address_line2,bill_to_address_postal_code,bill_to_address_state,bill_to_forename,bill_to_phone,bill_to_surname,bill_to_email,consumer_id,customer_ip_address,item_0_sku,item_0_code,item_0_name,item_0_quantity,merchant_defined_data1,merchant_defined_data2,merchant_defined_data3,merchant_defined_data4,merchant_defined_data5,merchant_defined_data7,merchant_defined_data20,merchant_defined_data31" />
                            <input type="text" hidden readOnly id="access_key" name="access_key" value="bcbb387eb358347bb7f4b0466b1e0082" />
                            <input type="text" hidden readOnly id="profile_id" name="profile_id" value="48CEBE92-B60B-4715-B1A6-AD2E77CE8EF9" />
                            <input type="text" hidden readOnly id="unsigned_field_names" name="unsigned_field_names" value="card_number,card_expiry_date,card_account_type,card_type,card_type_selection_indicator,card_cvn,payment_method,payer_authentication_transaction_mode" />
                        </>
                        :
                        <>
                            <input type="text" hidden readOnly id="signed_field_names" name="signed_field_names" value="access_key,profile_id,transaction_uuid,signed_field_names,unsigned_field_names,signed_date_time,locale,transaction_type,reference_number,amount,currency,bill_to_address_city,bill_to_address_country,bill_to_address_line1,bill_to_address_line2,bill_to_address_postal_code,bill_to_address_state,bill_to_forename,bill_to_phone,bill_to_surname,bill_to_email,consumer_id,customer_ip_address,item_0_sku,item_0_code,item_0_name,item_0_quantity,merchant_defined_data1,merchant_defined_data2,merchant_defined_data3,merchant_defined_data4,merchant_defined_data5,merchant_defined_data7,merchant_defined_data20,merchant_defined_data31" />
                            <input type="text" hidden readOnly id="access_key" name="access_key" value="f8f09f66a6d13e5f9418624f15c8f424" />
                            <input type="text" hidden readOnly id="profile_id" name="profile_id" value="6FBD5D61-DC02-4A07-80CB-67967B966CE9" />
                            <input type="text" hidden readOnly id="unsigned_field_names" name="unsigned_field_names" value="" />
                        </>
                }
            </div>
        </div>
    )
}

export default BillingInformation