import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Base_Url } from '../context';
import { useParams } from 'react-router-dom';
import { Footer, Navbar } from '../components';
import { Box, Button, Grid, Typography } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import QMLogo from "../assets/QM_icon.webp";
import SendGif from "../assets/sent.gif";
const ResponsePage = () => {
    const param = useParams();
    const [orderResponse, setOrderResponse] = useState();
    // eslint-disable-next-line
    useEffect(() => {
        getOrderResponse()
    }, []);

    const getOrderResponse = async () => {
        const orderId = param.id;
        const response = await axios.post(`${Base_Url}order/response/${orderId}`);
        console.log(response.data);
        setOrderResponse(response.data[0]);
    }
    return (
        <>
        <Box variant={'div'} className="full-vp">
            <Navbar />
            {
                (orderResponse !== null && orderResponse !== undefined && orderResponse.OrderReponse) ?
                    <Grid container mt={10} rowGap={4} className='flex-1' >

                        <Grid item xs={12} textAlign='center'  >
                            <img src={QMLogo} width={"80"} alt='QM Logo' />
                            {(orderResponse.OrderReponse.reason_code === '100') ?
                                <>
                                    <Typography variant='h5' align='center' color='green' mt={2}>Jazak Allah O Khair <br /> Your order has been completed successfully.</Typography>
                                </>
                                :
                                <Typography variant='h5' align='center' color='red' mt={2}>Your order has been failed.</Typography>
                            }
                        </Grid>

                        <Grid item xs={12} textAlign='center'  >
                            {(orderResponse.OrderReponse.reason_code === '100') ?
                                <img src={SendGif} width={"200"} alt='Success' />
                                :
                                <HighlightOffIcon color='error' sx={{ fontSize: '150px' }} />
                            }
                        </Grid>
                        <Grid item xs={12} display={'flex'} justifyContent={'center'}>
                            {(orderResponse.OrderReponse.reason_code === '100') ?
                                <Typography component={'p'} textAlign='center' sx={{ fontSize: '19', width : {md : '40%', xs : '80%'}, }}>An email has been sent to 
                                <Typography component={'span'} sx={{ fontWeight: 800, fontSize: '21px' }} > {orderResponse.User.Uid} </Typography > 
                                containing a receipt of the transaction.
                                <br/>
                                <br/>
                                <Button variant='contained' color='success' size='medium' onClick={() => { window.open('https://quranmajeed.com/') }} >Go Back To Quran Majeed</Button>
                                </Typography>
                                :
                                <Typography component={'div'} sx={{ width : {md : '40%', xs : '80%'}}}>
                                    <Typography variant={'h4'} textAlign={'center'} >Something went wrong.</Typography>

                                    <hr/>
                                    <Typography variant={'h6'} textAlign={'center'} sx={{ fontSize: '17'}}> Server message is :<Typography component={'span'} sx={{fontWeight : 800, fontSize : '18px'}} > {orderResponse.OrderReponse.message} </Typography>. 
                                    <br/> Your payment has been on hold by the bank. We need confirmation from your end to go ahead with the order. One of our represantatives will email you shortly. Kindly contact at
                                    <Typography component={'span'} sx={{fontWeight : 800, fontSize : '18px'}} > support@pakdata.com </Typography> for any inqueries.</Typography>
                                    <Typography variant={'h6'} textAlign={'center'}>Your error code is : {orderResponse.OrderReponse.reason_code}</Typography>
                                    <Typography variant={'h6'} textAlign={'center'}>Decision is {orderResponse.OrderReponse.decision}</Typography>
                                </Typography>
                                
                            }
                        </Grid>
                    </Grid>
                    : 
                    <Grid className='flex-1'>
                        <Typography component={'div'} variant='h5' textAlign={'center'} mt={20}>
                            No order response found related to OrderId : {param.id}. 
                            For any query please contact support@pakdata.com.
                        </Typography>
                    </Grid>
            }
            <Footer />
        </Box>

        </>
    )
}

export default ResponsePage