import VerticalTabs from "../components/tabs";

function AdminPage() {

  return (
    <>
        <VerticalTabs />
    </>
  )
}

export default AdminPage