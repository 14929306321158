import React from "react";
import { Footer, Navbar } from "../components";
import { useSelector } from "react-redux";
import EmptyCart from "../components/EmptyCart";
import ShowCheckout from "../components/ShowCart";
import { Box } from "@mui/material";
import QMLogo from '../assets/QM_icon.webp'
const Checkout = () => {

  const state = useSelector((state) => state.handleCart);

  return (
    <>
      <Box variant={'div'} className="full-vp" >
        <Navbar />
        <div className="container my-3 py-3 flex-1">
          <div className="text-center">
            <img src={QMLogo} width='70px' alt="QM Logo" />
          </div>
          <h1 className="text-center mt-3">Checkout Here</h1>
          <hr />
          {state.length ? <ShowCheckout /> : <EmptyCart />}
        </div>
        <Footer />
      </Box>
    </>
  );
};

export default Checkout;
