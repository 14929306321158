const configuration = [];
const handleConfiguration = (state = configuration, action) => {
    switch (action.type) {
        case "GetConfiguration":
            state = action.payload
            return state;

        default:
            return state
    }

}

export default handleConfiguration;