import React, { Suspense } from 'react';
import { Box } from "@mui/material";
import { Navbar, Main } from "../components";
import BasicAccordion from '../components/Accordin';
import { useSelector } from "react-redux";
import faqImg from '../assets/faq.webp'
import NavbarCartButton from '../components/NavbarCartButton';
const Product = React.lazy(() => import('../components/Products'));
const WayToPay = React.lazy(() => import('../components/WayToPay'));
const FeedBack = React.lazy(() => import('../components/FeedBack'));
const Footer = React.lazy(() => import('../components/Footer'));

function Home() {
  const configuration = useSelector((configuration) => configuration.handleConfiguration);
  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }} >
        <Navbar />
        <Main />
        <Suspense fallback={<div>Loading....</div>}>
          <Product />
          {
            (configuration && configuration.IsQurbani === "ON") ?
              <div>
                <div className="container my-3 py-3">
                  <div className="row">
                    <div className="col-12 text-center">
                      <img alt='FAQ IMG' src={faqImg} />
                    </div>
                  </div>
                </div>
                <BasicAccordion />
              </div>
              : null
          }
          <Box id="carButton" vairant={'div'} sx={{position : 'fixed', bottom : '20px', display : {xs: 'flex', md : 'none'}, justifyContent : 'center', width : '100%', zIndex : 20 }}>
            <NavbarCartButton />
          </Box>
          <WayToPay />
          <FeedBack />
          <Footer />
        </Suspense>
      </Box>
    </>
  )
}

export default Home